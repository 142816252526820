import { tokens } from '../../theme'
import { 
  Typography,
  useTheme
} from '@mui/material'
import Action from '../buttons/Action'
import { serviceList } from '../../static/serviceList'
import './index.css'

const ManagedServices = () => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const colors = tokens(mode)

  return (
    <section className='managed-services'>
      <div className='managed-services-title'>
        <h1>
          Providing businesses and non-profit organizations with affordable
          IT solutions through customized, secure, and reliable network services.
        </h1>

      </div>
      <div className='featured-services-container'>
        {serviceList.map((service, serviceIndex) => {
          return (
            <div className='featured-service' key={serviceIndex}>
              <div style={{display:"flex", overflow:"hidden", position:"relative"}}>
                <img src={service.image} alt={service.alt} />
              </div>
              <div style={{textAlign:"left", padding:"0.5rem 1.2rem"}}>
                <div style={{position:"absolute", height:"6rem", top:"12rem", textAlign:"left"}}>
                  <Typography variant='h2' style={{margin:"1rem 0", fontSize:15, fontFamily:"Inter", color:colors.green['primary'], padding:"0 1rem 0 0"}}><br></br>{service.service}</Typography>
                </div>
                <div style={{position:"absolute", height:"6rem", top:"19rem", textAlign:"left"}}>
                  <Typography variant='h3' style={{margin:"1rem 0", fontFamily:"Inter", fontWeight:1000, fontSize:18, padding:"0 0.7rem 0 0"}}>{service.title}</Typography>
                </div>
                <hr style={{position:"relative", top:"14.5rem"}}/>
                <div style={{position:"absolute", height:"9rem", top:"29.5rem", padding:"0 1rem 0 0", textAlign:"left"}}>
                  <Typography variant='p' style={{margin:"1rem 0 2rem 0", fontSize:17}}>{service.text}</Typography>
                </div>
                <div style={{position:"absolute", bottom:"3rem", left:"1.1rem"}}>
                  <Action
                    to={service.to}
                    text='Learn More'
                    isDesktop={true}
                    colors={colors}
                    mode={mode}
                    shadow={false}
                    aria={service.aria}
                  />
                </div>
              </div>
            </div>
        )})}
      </div>
    </section>
  )
}

export default ManagedServices