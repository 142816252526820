export const serviceList = [
  {
    title:"24/7 real-time monitoring of all endpoints, network devices and cloud environments",
    service:"MANAGED SECURITY OPERATIONS CENTER",
    image:"/services/soc.webp",
    style:{},
    text:"Comprehensive threat prevention, detection and response with 24/7 monitoring and expert analysis.",
    to:"/network-and-cybersecurity#managed-security-operations-center",
    alt:"Managed Security Operations Center",
    aria:"managed-security-operations-center"
  },
  {
    title:"Behavior-based attack prevention",
    service:"ENDPOINT THREAT DETECTION & RESPONSE",
    image:"/services/endpoint-threat-detection.webp",
    style:{},
    text:"Continuous monitoring of workstations and company devices to detect and respond to cybersecurity threats.",
    to:"network-and-cybersecurity#endpoint-detection-response",
    alt:"Endpoint Threat Detection and Response",
    aria:"endpoint-threat-detection-and-response"
  },
  {
    title:"On-demand simulated cyber attacks on your organization",
    service:"VIRTUAL NETWORK PENETRATION TESTING",
    image:"/services/network-security-testing.webp",
    style:{},
    text:"Virtual network penetration testing attempting to bypass all layers of a company’s security posture.",
    to:"/network-and-cybersecurity",
    alt:"Virtual Network Penetration Testing",
    aria:"virtual-network-penetration-testing"
  },
  {
    title:"Scheduled scans geared to find security flaws of every kind",
    service:"VULNERABILITY SCANNING",
    image:"/services/vulnerability-management.webp",
    style:{},
    text:"Internal and external network security scans against ports, security devices, configurations and applications with recommended remediation tasks to fortify the network.",
    to:"/network-and-cybersecurity#vulnerability-management",
    alt:"Vulnerability Management",
    aria:"vulnerability-management"
  },
  {
    title:"Educating employees to identify threats",
    service:"SECURITY AWARENESS TRAINING",
    image:"/services/security-awareness-training.webp",
    style:{},
    text:"Simulated email phishing campaigns, training and reporting to ensure employees are always staying diligent and aware of every type of email threat.",
    to:"/network-and-cybersecurity#security-awareness-training",
    alt:"Security Awareness Training",
    aria:"security-awareness-training"
  },
  {
    title:"Reduce spam by 99.9% and take control of your inbox",
    service:"ADVANCED EMAIL THREAT PROTECTION ",
    image:"/services/email-spam-filtering.webp",
    style:{},
    text:"Sophisticated spam filtering engine, self-quarantine for spam management, email link protection, message retraction options and 24x7x365 analysis team to analyze new threats.",
    to:"/network-and-cybersecurity#advanced-email-threat-detection",
    alt:"Advanced Email Spam Filtering",
    aria:"advanced-email-spam-filtering"
  }
]
