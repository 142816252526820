import { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material'
import { tokens } from '../../theme'
import { BsChevronLeft, BsChevronRight/*, BsChevronCompactLeft, BsChevronCompactRight */ } from 'react-icons/bs';
import PropTypes from 'prop-types'
import Action from '../buttons/Action'
import './index.css'

const Carousel = ({ slides, isDesktop }) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if(currentIndex >= slides.length) {
        setCurrentIndex(0)
      }else{
        nextSlide()
      }
    },8000)
    return () => clearTimeout(timer)
  })

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (

    <div style={{
      display: "flex",
      position:"relative",
      height:"100vh",
      width:isDesktop ? "100vw" : "100vw",
      paddingTop:"5rem",
      overflow:"hidden",
      justifyContent:"center",
      alignItems:"center"
    }}>
      <div style={{position:"absolute", top:"3.5rem", marginLeft:0, marginRight:0}}>
        <img className={slides[currentIndex].className} src={slides[currentIndex].url} alt={slides[currentIndex].alt}/>
      </div>
      <div className='chevy' style={{
        borderRadius:"50%",
        color:"#fff",
        position:"absolute",
        cursor:"pointer",
        left:"1rem",
        top:"50%",
        marginTop: "auto", 
        marginBottom: "auto",
        padding:"0.6rem 0.85rem",
        opacity:"0.3",
        zIndex:2
      }}>
        <BsChevronLeft onClick={prevSlide} color="#fcfcfc" size={34} />
      </div>

      <div className='chevy' style={{
        borderRadius:"50%",
        color:"#fff",
        position:"absolute",
        cursor:"pointer",
        right:"1rem",
        top:"50%",
        marginTop: "auto", 
        marginBottom: "auto",
        padding:"0.65rem 0.9rem 0.6rem 0.9rem",
        opacity:"0.3",
        zIndex:2
      }}>
        <BsChevronRight onClick={nextSlide} color="#fcfcfc" size={34} />
      </div>
      <Box sx={{
        position:"absolute",
        left:0,
        right:0,
        bottom:"5rem",
        marginLeft: "auto", 
        marginRight: "auto"
      }}>
        <Box sx={{display:"flex",flexDirection:"column", textAlign:"center"}}>
          {slides[currentIndex].description.map((text, textIndex) => {
            return <div className='image_title' key={textIndex}>{text}</div>
          })}
        </Box>
        <div style={{display:"flex", padding:"1rem 0 0 0", justifyContent:"center"}}><Action to={slides[currentIndex].to} text='Learn More' colors={colors} isDesktop={true} /></div>
      </Box>
    </div>
  )
}

Carousel.propTypes = {
  slides: PropTypes.array.isRequired,
  isDesktop: PropTypes.bool.isRequired
}

export default Carousel