import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Box, Typography, Slide, useMediaQuery, useTheme } from '@mui/material'
import { tokens } from '../theme'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import CloseIcon from '@mui/icons-material/Close'
import { FaPhoneVolume } from "react-icons/fa6";
import { PiPhone } from "react-icons/pi"
import '../index.css'
import { menuItems } from '../static/menuItems'
import Action from './buttons/Action'

const MobileMenu = () => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const colors = tokens(mode)
  const isPhone = useMediaQuery(theme.breakpoints.down('tablet'))
  const tablet = useMediaQuery(theme.breakpoints.down('laptop'))
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))
  const isTablet = tablet && !isPhone

  const [open, setMenu] = useState(false)
  const toggleMenu = () => setMenu(!open)

  return (
    <header id='header' style={{position:"fixed", width:"100%", backgroundColor:colors.grey[900], zIndex:2000, padding:isTablet ? "1rem 0" : 0}}>
      <div className='menu' style={{position:"relative", display:"flex", margin:"auto 0", padding:isPhone ? "0 1.5rem" : "0 4rem", alignItems:"center", justifyContent:"space-between"}}>
        <div style={{zIndex:2024}}>
          <Link to="/" role="link" aria-label='Home'>
            <img
              style={{width:isPhone ? "7rem" : "10rem", padding:0, margin:"1.2rem 0 0.6rem 0"}}
              src="/logo-7l-full.webp"
              alt='7 Layer IT Solutions Logo'
            />
          </Link>
        </div>
        <div style={{display:"inline-flex", gap:isMobile ? "0.5rem" : "2rem", marginTop:"0.8rem"}}>
          <div style={{zIndex:2001, marginTop:"0.3rem"}}>
            <Action
              to="tel:+18187930070"
              text='Contact'
              icon={<PiPhone style={{marginTop:"0.45rem", fontSize:28}} />}
              isDesktop={false}
              colors={colors}
              mode={mode}
              aria="call-support"
            />
          </div>
          {!open && 
            <div style={{zIndex:1999}}>
              <MenuRoundedIcon
                sx={{m:"auto"}}
                style={{ color:colors.grey[400], fontSize:55}}
                onClick={toggleMenu}
                aria-label="open-menu"
              />
            </div>}
          {open && <div style={{zIndex:1999}}>
            <CloseIcon onClick={toggleMenu} aria-label="close-menu" sx={{fontSize:55, color:colors.grey[400]}}/>
          </div>}
        </div>

      </div>
      <div style={{zIndex:1000,position:"absolute", top:0, right:0, left:0, height:"5rem", backgroundColor:colors.grey[900]}}>

      </div>
      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
        <Box sx={{
          zIndex:1,
          backgroundColor:colors.grey[900],
          paddingLeft:isPhone ? "1rem" : "2rem",
          top:0,
          left:0,
          bottom:0,
          display:"flex",
          justifyContent:"left",
          position:"fixed",
          right:0,
          overflowY:"scroll",
          '& li': { padding:"0" },
          '& li:hover': { backgroundColor:"inherit", color:colors.grey[500]}
        }}>
          <div style={{marginTop:"0.7rem"}}>
            <nav style={{paddingTop:"5rem", zIndex:-1}}>
              <ul className='nav-links' style={{display:"flex", flexDirection:"column", padding:"1rem 0", lineHeight:"2rem"}}>
                {menuItems.map(({ title, path, aria, children }, index) => {
                  return (
                    <li key={index} style={{padding:"0 1.8rem"}}>
                      <NavLink role='menu' aria-label={aria} to={path} className={({ isActive }) => (isActive ? 'active-link nav-link' : 'nav-link')} onClick={toggleMenu}>
                        <Typography variant='mobileMenu'>{title}</Typography>
                      </NavLink>
                      <ul style={{backgroundColor:colors.grey[900], lineHeight:"1.2rem", margin:"0.5rem 0 1rem 0"}}>
                        {children.map(({ subtitle, path, icon, aria }, index) => {
                          return (
                            <li key={index}>
                              <Link to={path} aria-label={aria} onClick={toggleMenu}>
                                <div style={{display:"flex", flexDirection:"row"}}>
                                  {!isPhone && <div style={{marginTop:"0.4rem"}}>{icon}</div>}
                                  <Typography fontSize={isPhone ? 14 : 16} variant='menuItem'>{subtitle}</Typography>
                                </div>
                              </Link>
                            </li>
                          )
                        })}
                        {(tablet || isPhone || isMobile) && title === 'Client Resources' ? <li><Link to='contact' aria-label='contact-us' onClick={toggleMenu}>                                <div style={{display:"flex", flexDirection:"row"}}>
                                  {!isPhone && <div style={{marginTop:"0.4rem"}}><FaPhoneVolume style={{fontSize:19,marginRight:"0.8rem"}}/></div>}
                                  <Typography fontSize={isPhone ? 14 : 16} variant='menuItem'>Contact Us</Typography>
                                </div></Link></li> : ''}
                      </ul>
                    </li>
                  )
                })}
              </ul>
            </nav>
          </div>
        </Box>
      </Slide>
    </header>
  )
}

export default MobileMenu