import { Box, Button, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { tokens } from "../theme"
import { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import useRecaptcha from "./hooks/useRecaptcha"
import { Formik } from "formik"
import { ValidationSchema } from "./ValidationSchema"
import { createAssessment, createTicket } from '../request'
import Interests from "./Interests"
import '../index.css'

const toggleCaptchaBadge = (show) => {
  const badge = document.getElementsByClassName('grecaptcha-badge')[0];
  if (badge && badge instanceof HTMLElement) {
    badge.style.visibility = show ? 'visible' : 'hidden';
  }
}

const ContactForm = ({onChangeState}) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  const action = 'contact'

  const executeRecaptcha = useRecaptcha()

  useEffect(() => {
    toggleCaptchaBadge(true);
    return () => toggleCaptchaBadge(false);
  }, [])

  useEffect(() => {
    const textArea = document.querySelector(
      "textarea:not([name='comment'])"
    )
    textArea.id = 'hidden'
  }, [])

  const [interests, dispatch] = useReducer(setInterests, [])

  const toggleInterest = (e) => {
    dispatch({
      name: e.target.name,
      checked: e.target.checked
    })
  }

  const handleFormSubmit = async (values) => {
    let recaptchaToken = await executeRecaptcha(action)

    if (!recaptchaToken) {
      recaptchaToken = await executeRecaptcha(action)
    }

    if (recaptchaToken) {
      const success = await createAssessment(recaptchaToken, action)
      if (success) {
        values['interests'] = interests.join(", ")
        let response = await createTicket(values)
        if (response === 'Error creating new ticket') {
          response = await createTicket(values)
          if (response === 'Error creating new ticket') {
            onChangeState('error')
          } else {
            onChangeState('confirm')
          }
        } else {
          onChangeState('confirm')
        }
      } else {
        onChangeState('error')
      }
    } else {
      onChangeState('error')
    }
  }

  return (
    <Box sx={{
      mt:isDesktop ? "2rem" : "",
      display:"flex",
      flexDirection:"column",
      width:'100%',
      '& .MuiInputBase-input':{fontSize:24},
      '& label':{fontSize:14,opacity:0.5}
    }}>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          company: "",
          subject: "",
          comment: "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleFormSubmit(values)
            actions.setSubmitting(false)
          }, 1000)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="2rem"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isDesktop ? undefined : "span 4"
                },
                '& .Mui-error': {
                  color:"black"
                },
                m:"0.2rem",
                pt:"0.3rem",
                '& label':{textWrap:"nowrap"}
              }}
            >
              <TextField
                fullWidth
                type="text"
                label="first name *"
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{gridColumn: "span 2"}}
                autoComplete="given-name"
              />
              <TextField
                fullWidth
                type="text"
                label="last name *"
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{gridColumn: "span 2"}}
                autoComplete="family-name"
              />
              <TextField
                fullWidth
                type="text"
                label="email *"
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{gridColumn: "span 2"}}
                autoComplete="email"
              />
              <TextField
                fullWidth
                type="tel"
                label="phone *"
                onChange={handleChange}
                value={values.phone}
                name="phone"
                error={!!touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
                sx={{gridColumn: "span 2"}}
                autoComplete="tel-national"
              />
              <TextField
                fullWidth
                type="text"
                label="company"
                onChange={handleChange}
                value={values.company}
                name="company"
                error={!!touched.company && !!errors.company}
                helperText={touched.company && errors.company}
                sx={{gridColumn: "span 4"}}
                autoComplete="organization"
              />
            </Box>
            <Interests interests={interests} onChangeInterest={toggleInterest}/>
            <Box
              display="grid"
              gap="2rem"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isDesktop ? undefined : "span 4"
                },
                '& .Mui-error': {
                  color:"black"
                },
                m:"0.2rem",
                pt:"0.3rem",
                '& label':{textWrap:"nowrap"}
              }}
            >
              <TextField
                fullWidth
                type="text"
                label="subject *"
                onChange={handleChange}
                value={values.subject}
                name="subject"
                error={!!touched.subject && !!errors.subject}
                helperText={touched.subject && errors.subject}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                multiline
                rows={8}
                type="text"
                label="comment *"
                onChange={handleChange}
                value={values.comment}
                name="comment"
                error={!!touched.comment && !!errors.comment}
                helperText={touched.comment && errors.comment}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" m="2rem 1rem" sx={{'& .css-1faqppt-MuiButtonBase-root-MuiButton-root:hover':{backgroundColor:colors.primary[900]}}}>
              <Button disableFocusRipple type="submit" variant="contained" sx={{backgroundColor:colors.green['primary']}}>
                <Typography variant='h5' fontWeight={400}>Submit</Typography>
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}

ContactForm.propTypes = {
  onChangeState: PropTypes.func.isRequired
}

const setInterests = (interests, event) => {
  if (event.checked) {
    return [...interests, event.name]
  } else {
    let updatedInterests = [...interests]
    const index = updatedInterests.indexOf(event.name)
    if (index !== -1) {
      updatedInterests.splice(index, 1)
      return updatedInterests
    }
  }
}

export default ContactForm