import { Typography, useMediaQuery, useTheme } from '@mui/material'

const FormError = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  const isPhone = useMediaQuery(theme.breakpoints.down('tablet'))

  return (
    <div className='page' style={{
      display:"flex",
      flexDirection:"column",
      overflow:"hidden"
    }}>
      <div style={{display:"flex", flexDirection:"column", textAlign:isPhone ? "left" : "center"}}>
        <div style={{margin:isDesktop ? "" : "1rem 2rem"}}>
          <Typography variant={isPhone ? "h2" : "h1"}>Error</Typography>
        </div>
        <div style={{margin:isDesktop ? "2rem 3rem" : "2rem", textAlign:isPhone ? "left" : "center"}}>
          <Typography variant="p" fontSize={22} fontWeight="normal">
            There was a problem submitting your request. Please try again.
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default FormError