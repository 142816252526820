import axios from 'axios'

const apiRequest = axios.create({
  baseURL: import.meta.env.API_BASE_URL,
  withCredentials: false,
})

export async function createAssessment(token, action) {
  let success = true
  try {
    let response = await apiRequest.post('/api/create_assessment', {token:token, action:action})
    if (!response.data.success || response.data.action !== action || response.data.score < 0.5) {
      success = false
    }
    return success
  } catch (error) {
    console.log(error)
  }
}

export async function createTicket(ticketData) {
  try {
    let response = await apiRequest.post('/api/create_ticket', ticketData)
    return response.data.item_id
  } catch (error) {
    console.log(error)
    return "Error creating new ticket"
  }
}