import { useState, useEffect, useCallback } from 'react'

const useRecaptcha = () => {
    const [isRecaptchaReady, setIsRecaptchaReady] = useState(false)
    const siteKey = import.meta.env.VITE_CLIENT_SITE_KEY

    useEffect(() => {
        if (window.grecaptcha) {
            setIsRecaptchaReady(true)
        } else {
            const script = document.createElement('script')
            script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${siteKey}`
            script.async = true
            document.head.appendChild(script)
            script.onload = () => setIsRecaptchaReady(true)
        }
    }, [siteKey])

    const executeRecaptcha = useCallback(async (action) => {
        if (isRecaptchaReady && window.grecaptcha) {
            try {
                return await window.grecaptcha.execute(siteKey, { action })
            } catch (error) {
                console.log(error)
            }
        }
        return null
    }, [isRecaptchaReady, siteKey])

    return executeRecaptcha
};

export default useRecaptcha