import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './components/App/index.jsx'
import ScrollToTop from './components/ScrollToTop.jsx'
import ScrollToAnchor from './components/ScrollToAnchor.jsx'
import './index.css'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <ScrollToAnchor />
      <App />
    </BrowserRouter>
  </React.StrictMode>
)
