import * as yup from "yup"

export const ValidationSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup
    .string()
    .email("Please enter valid email address")
    .required("required"),
  phone: yup
    .string()
    .required("required")
    .matches(/^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/, "Please enter valid phone number"),
  subject: yup.string().required("required"),
  comment: yup.string().required("required"),
  company: yup.string()
})