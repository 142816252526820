import { Link } from 'react-router-dom'
import { Box, Fab, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const Action = ({ to = null, text, icon, colors, isDesktop=true, shadow=true , target='', aria=''}) => {

  return (
    <Box sx={{'& :hover':{color:colors.green['primary']}}}>
      {to ? <Link to={to} target={target} aria-label={aria}>
        <Fab

          disableRipple
          variant=/* {isDesktop ?  */"extended"/*  : ""} */
          aria-label={text}
          sx={{
            boxShadow:shadow ? "" : "none",
            backgroundColor:colors.green['primary'],
            color: "#ffffff",
            zIndex:"0 !important",
            m:"0",
            p:isDesktop ? "0 1.5rem" : "0rem",
            maxHeight:"2.8rem",
            opacity:1
            /* boxShadow:'none'
            /* maxWidth:isDesktop ? "auto" : "1rem",
            maxHeight:isDesktop ? "2.7rem" : "1rem" 
            /* maxWidth: "auto", */
            /* maxHeight:isDesktop ? "2rem" : "1rem" */
        }}>
          
          <div style={{display:"flex", flexDirection:"row", gap:"0.4rem"}} >  
            {!isDesktop && icon && <div>{icon}</div>}
            {isDesktop && <div><Typography color="#ffffff" variant="fab">{text}</Typography></div>}
          </div>
        </Fab>
      </Link> : 
              <Fab
              disableRipple
              variant=/* {isDesktop ?  */"extended"/*  : ""} */
              aria-label={text}
              sx={{
                boxShadow:shadow ? "" : "none",
                backgroundColor:colors.green['primary'],
                color: "#fcfcfc",
                zIndex:"0 !important",
                m:"0",
                p:isDesktop ? "0 1.5rem" : "0rem",
                maxHeight:"2.8rem",
                opacity:0.9
                /* boxShadow:'none'
                /* maxWidth:isDesktop ? "auto" : "1rem",
                maxHeight:isDesktop ? "2.7rem" : "1rem" 
                /* maxWidth: "auto", */
                /* maxHeight:isDesktop ? "2rem" : "1rem" */
            }}>
              
              <div style={{display:"flex", flexDirection:"row", gap:"0.4rem"}} >  
                {!isDesktop && icon && <div>{icon}</div>}
                {isDesktop && <div><Typography color="#ffffff" variant="fab">{text}</Typography></div>}
              </div>
            </Fab>}
    </Box>
  )
}

Action.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.any,
  isDesktop: PropTypes.bool,
  colors: PropTypes.object.isRequired,
  shadow: PropTypes.bool,
  target: PropTypes.string,
  aria: PropTypes.string
}

export default Action
