import { Link } from 'react-router-dom'
import { Fab, Typography, useMediaQuery, useTheme } from '@mui/material'
import ScrollTop from '../../components/buttons/ScrollToTop'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Level1Support from './level-1-support-analyst'
import Level2Support from './level-2-support-analyst'
import { useReducer } from 'react'

const Careers = (props) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))
  const isPhone = useMediaQuery(theme.breakpoints.down('tablet'))
  const [expanded, dispatch] = useReducer(setExpanded, false);

  const handleChange = (event, panel) => {
    dispatch({
      name: panel
    })
  }

  return (
    <div className='page' style={{
      display:"flex",
      flexDirection:"column",
      overflow:"hidden"
    }}>
      <div style={{display:"flex", flexDirection:"column", textAlign:isPhone ? "left" : "center"}}>
        <div style={{display:"inline-flex", margin:isDesktop ? "" : "0 2rem", justifyContent:"center"}}>
          <img src='/7-layer-it-solutions-blk.webp' style={{marginTop:"-1.2rem", width:"5rem", height:"4.736rem"}}/>
          <h1 style={{fontSize:isDesktop ? "2rem" : "1.5rem"}}>Careers</h1>
        </div>
        <div style={{margin:"2rem", textAlign:"left"}}>
          <Typography variant="p" fontSize={22} fontWeight="normal">
          One of the reasons we started this company was to create a family-like work environment, where everyone has a voice. We work hard, but we like to have fun as well. Our employees will always be very important to us and we always try to implement programs, events, and initiatives to show our gratitude to all of you.
          </Typography>
        </div>
        
        <div style={{display:"flex", flexDirection:"column", margin:isDesktop ? "4rem 0" : "2rem"}}>
          <div style={{marginBottom:"1.2rem", textAlign:isPhone ? "left" : "center"}}>
          <h2>Roles</h2>
          </div>
          {/* <div style={{textAlign:isPhone ? "left" : "center"}}>
            <Typography variant="p" fontSize={22} fontWeight="normal">There are no open roles at this time.</Typography>
          </div> */}
          <div style={{margin:isDesktop ? "1rem": 0, textAlign:"left"}}>
            <Level1Support isDesktop={isDesktop} expanded={expanded} onAccordionChange={handleChange}/>
            <Level2Support isDesktop={isDesktop} expanded={expanded} onAccordionChange={handleChange}/>
          </div>
        </div>

        <div style={{display:"flex", flexDirection:isDesktop ? "row" : "column", textAlign:"center", marginTop:"2rem", justifyContent:"center", gap:"2rem"}}>
        <div>
            <Link target='_blank' to='https://www.greatplacetowork.com/certified-company/7080255'>
              <img alt='Great Place To Work Certification' style={{borderRadius:"0.5rem", border:"2px solid gray", height:isDesktop ? "20rem" : isMobile ? "9.4rem" : "11.25rem", /* boxShadow: theme.palette.mode == 'light' ? "1px 10px 15px #d3d3d3" : "" */}}src="/We're_Certified!_US.png" />
            </Link>
          </div>          
          <div>
            <img src='/company_image_3.jpg' alt='7 Layer IT Solutions Holiday Party' style={{borderRadius:"0.5rem", border:"2px solid gray", height:isDesktop ? "20rem" : isMobile ? "10rem" : "12rem"}}/>
          </div>

        </div>
      </div>

      {isDesktop && <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>}
    </div>
  )
}

const setExpanded = (expanded, newExpanded) => {
  return newExpanded.name === expanded ? false : newExpanded.name
}

export default Careers