import { Link } from 'react-router-dom'
import { Box, Fab, Typography, useMediaQuery, useTheme } from '@mui/material'
import { tokens } from '../../theme'
import ScrollTop from '../../components/buttons/ScrollToTop'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { AiOutlineAudit } from "react-icons/ai" /* audit */
import { IoAnalyticsSharp } from "react-icons/io5" /* analytics & reporting */
import { FaWindows } from "react-icons/fa6"
import { BiSupport } from "react-icons/bi" /* Unlimited Technical Support */
import { BsPersonGear } from "react-icons/bs" /* On-Site & Network Services */
import { PiGauge } from "react-icons/pi" /* Remote Monitoring */
import '../../index.css'

const ManagedServices = (props) => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const colors = tokens(mode)
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  const isPhone = useMediaQuery(theme.breakpoints.down('tablet'))
  const isTablet = !isPhone && !isDesktop

  return (
    <div className='services' style={{
      display:"flex",
      flexDirection:"column", 
      overflow:"hidden",
      width:"100%",
      margin:0
    }}>
      {isDesktop ? <>
        <div style={{display:"flex", flexDirection:"column", textAlign:"center"}}>
          <div>
            <h1 style={{fontSize:"2rem"}}>
              Managed IT Services
            </h1>
            <div style={{margin:"2rem 10rem", textAlign:"center"}}>
              <Typography variant="p" fontSize={20} fontWeight="normal">
              Are you tired of fluctuating IT bills every month?<br></br>
              Tired of being charged every time you have an IT issue?<br></br>
              Tired of having to tell your IT company there is an issue instead of them already knowing?<br></br>
              Do you want a trusted IT firm you can call anytime for IT consulting without being charged?<br></br>
              <br></br>
              At 7 Layer IT Solutions, we charge a fixed, monthly fee and cover everything. We want to be your trusted IT resource. We’ll handle everything IT-related so you can focus on your business.<br></br><br></br>You need an IT company that cares about your business, learns your business processes and figures out ways to enable your technology to actually make you money. Let’s talk and see how we can bring value to your business.
              </Typography>
            </div>
          </div>
        </div>
        <div style={{width:"90%", margin:"2rem auto", height:"1rem",backgroundColor:colors.green['primary'], opacity:0.7, verticalAlign:"top"}}></div>
        <div style={{margin:"2rem 1rem 0 1rem", fontSize:17, textAlign:"center", display:"inline-flex", gap:"2rem", justifyContent:"center"}}>

          <Box sx={{'&:hover': {color:colors.green['primary']}, color:colors.primary[500],  borderRadius:"10px"}}>
            <Link to='#unlimited-technical-support'>
              <BiSupport fontSize={40} style={{opacity:0.7}}/>
              <Typography variant='h2'fontWeight={400} fontSize={17} fontFamily="Nunito Sans" >Unlimited<br></br>Technical Support</Typography>
            </Link>
          </Box>

          <Box sx={{'&:hover': {color:colors.green['primary']}, color:colors.primary[500], borderRadius:"10px"}}>
            <Link to='#remote-monitoring-management'>
              <PiGauge fontSize={40} style={{opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Remote Monitoring<br></br>& Management</Typography>
            </Link>
          </Box>
          
          <Link to='#on-site-it-network-services'>
          <Box sx={{'&:hover': {color:colors.green['primary']},color:colors.primary[500],  borderRadius:"10px"}}>
            <BsPersonGear fontSize={40} style={{opacity:0.7}}/>
            <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">On-Site IT<br></br>& Network Services</Typography>
          </Box>
          </Link>
          <Link to='#windows-patching-updates'>
          <Box sx={{'&:hover': {color:colors.green['primary']},color:colors.primary[500],  borderRadius:"10px"}}>
            <FaWindows fontSize={40} style={{opacity:0.7}}/>
            <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Windows Patching<br></br>& Updates</Typography>
          </Box>
          </Link>
          <Link to='#business-technology-audit'>
          <Box sx={{'&:hover': {color:colors.green['primary']},color:colors.primary[500],  borderRadius:"10px"}}>
            <AiOutlineAudit fontSize={40} style={{opacity:0.7}}/>
            <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Business<br></br>Technology Audit</Typography>
          </Box>
          </Link>
          <Link to='#it-analytics-reporting'>
          <Box sx={{'&:hover': {color:colors.green['primary']},color:colors.primary[500],  borderRadius:"10px"}}>
            <IoAnalyticsSharp style={{fontSize:40, opacity:0.8}}/>
            <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Analytics<br></br>& Reporting</Typography>
          </Box>
          </Link>
          <p id='unlimited-technical-support' style={{lineHeight:1}}></p>
        </div>
        <div className='service' style={{margin:"4rem 0 1rem 0", position:"relative"}}>
          <div style={{display:'flex', flexDirection:"row", margin:"5rem 15rem"}}>
            <div style={{display:"flex", flexDirection:"column", marginTop:"1rem"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Unlimited Technical Support
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Whether your company opens one or 100 service requests during the month, there is no additional charge. All technical support is included in your monthly charges. Trust us, as much as we LOVE our clients, we don’t want you to have to call us for IT issues. We are very serious about setting up standards and implementing solid networks that work right. We would much rather spend time communicating with our clients about how we can add value to their businesses.
                <p id='remote-monitoring-management' style={{marginBottom:"1rem", lineHeight:1}}></p>
              </Typography>
            </div>
            <div style={{margin:"-1rem 0 0 0"}}><img alt='Unlimited Technical Support' src='/services/unlimited-technical-support.webp' style={{width:"18rem"}} /></div>
          </div>

          <div style={{display:'flex', flexDirection:"row", margin:"5rem 15rem"}}>
            <div style={{margin:"0 2rem 0 0"}}><img alt='Remote Monitoring and Management' src='/services/remote-monitoring.svg' style={{width:"18rem"}} /></div>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
            <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Remote Monitoring & Management
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                We will proactively monitor your network and everything on it using our Remote Monitoring and Management (RMM) system. We will let you know if something looks out of the ordinary and our Support Team will fix it; because after all, we love this stuff! Hard drive out of space? Internet down? Virus or malware on your PC? No matter what the issue, our system allows us to deliver proactive IT support and save your business time and money. Our system will do its best to resolve the issues by itself using its configured scripts and fixes without even needing our support staff. And, the best part is it’s all included with our monthly services!
                <p id='on-site-it-network-services' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>

          <div style={{display:'flex', flexDirection:"row", margin:"5rem 15rem"}}>
            <div style={{display:"flex", flexDirection:"column", marginTop:"1rem"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                On-Site IT & Network Services
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                We think of ourselves as your IT Department, not an outsourced company that is disengaged from your business. Our experienced team of professional, patient and courteous technicians and engineers love technology. We will learn your business and processes to provide the best support possible. It is our job to make your lives easier when it comes to technology. We can be nosy when it comes to our clients’ operations because if we don’t know how they do business, we can’t provide the best technology advice.
                <p id='windows-patching-updates' style={{lineHeight:1}}></p>
              </Typography>
            </div>
            <div style={{margin:"-1rem 0 0 0"}}><img alt='On-Site Computer Support' src='/services/on-site-support.svg' style={{width:"18rem"}} /></div>
          </div>

          <div style={{display:'flex', flexDirection:"row", margin:"5rem 15rem"}}>
            <div style={{marginRight:"2rem"}}><img alt='Windows Patching and Updates' src='services/windows-patching.svg' style={{width:"18rem"}} /></div>
              <div style={{display:"flex", flexDirection:"column", margin:"auto 0"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>

              Windows Updates & 3rd Party Patching
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Are you installing Windows updates and patches manually? Once our engineers test and approve Windows patches and updates, our Remote Monitoring and Management (RMM) system proactively installs the updates behind the scenes. This ensures that all computers and servers are constantly being updated and stay as secure as possible. Our team will also update any Line of Business (LOB) applications whenever new versions or updates are released so that you are always up-to-date and in compliance! 
                <p id='business-technology-audit' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>

          <div style={{display:'flex', flexDirection:"row", margin:"5rem 15rem"}}>
            <div style={{display:"flex", flexDirection:"column", marginTop:"1rem"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Business Technology Audit
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Our job is to ensure that our clients use technology to gain a competitive advantage in their industry. We love meeting with clients to learn how we can help them work smarter and increase efficiency. We do not deliver cookie-cutter solutions. We customize each client’s solution based on their budget, needs and prospective growth. We meet with our clients often to ensure we are informed about their IT and operational goals so that we can strategize the best way to facilitate those changes.
                <p id='it-analytics-reporting' style={{lineHeight:1}}></p>
              </Typography>
            </div>
            <div style={{margin:"-1rem 0 0 0rem"}}><img alt='Business Technology Review' src='/services/technology-business-review.svg' style={{width:"18rem"}} /></div>
          </div>
          <div style={{display:'flex', flexDirection:"row", margin:"5rem 15rem"}}>
          <div style={{marginRight:"2rem"}}><img alt='IT Analytics Report' src='/services/it-analytics-reporting.svg' style={{width:"18rem"}} /></div>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 0"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Analytics & Reporting
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                We can run all kinds of reports about our support and the network environment. Need to see how many service requests were created for the month? Want to see how many computers are in the environment with certain specs? Reporting allows us to extract data so that you can make informed decisions about IT operations, purchasing, service trends, etc. Let’s take a look at how we can use the data to provide even more effective IT support.
              </Typography>
            </div>
          </div>
        </div>
        <ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </>
      : /* ************************** MOBILE DEVICES ************************** */
      <>
        <div style={{display:"flex", flexDirection:"column", textAlign:isPhone ? "left" : "center"}}>
          <div style={{margin:"1rem 2rem 3rem 2rem"}}>
            <h1 style={{fontSize:"1.5rem", margin:"0 2rem"}}>
              Managed IT Services
            </h1>
            <div style={{margin:"2rem", textAlign:isPhone ? "left" : "center"}}>
              <Typography variant="p" fontSize={22} fontWeight="normal">
              Are you tired of fluctuating IT bills every month?<br></br>
              Tired of being charged every time you have an IT issue?<br></br>
              Tired of having to tell your IT company there is an issue instead of them already knowing?<br></br>
              Do you want a trusted IT firm you can call anytime for IT consulting without being charged?<br></br>
              <br></br>
              At 7 Layer IT Solutions, we charge a fixed, monthly fee and cover everything. We want to be your trusted IT resource. We’ll handle everything IT-related so you can focus on your business.<br></br><br></br>You need an IT company that actually cares about your business, learns your business processes and figures out ways to enable your technology to actually make you money. Let’s talk and see how we can bring value to your business.
              </Typography>
            </div>
          </div>
        </div>
        <div className='service' style={{margin:"2rem 0", position:"relative"}}>
          <div style={{display:'flex', margin:isTablet ? "4rem" : "2rem"}}>
            <div style={{display:"flex", flexDirection:"column", marginTop:"1rem"}}>
              <div style={{margin:"1rem auto"}}><img alt='Unlimited Technical Support' src='/services/unlimited-technical-support.webp' style={{width:"10rem"}} /></div>
              <Typography variant='h2' fontSize={22} style={{textAlign:"center", padding:"", textWrap:isPhone ? "wrap" : "nowrap"}}>
                Unlimited Technical Support
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Whether your company opens one or 100 service requests during the month, there is no additional charge. All technical support is included in your monthly charges. Trust us, as much as we LOVE our clients, we don’t want you to have to call us for IT issues. We are very serious about setting up standards and implementing solid networks that work right. We would much rather spend time communicating with our clients about how we can add value to their businesses.
                <p id='remote-monitoring-management' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? " 2rem 4rem 4rem 4rem" : "2rem"}}>
            <div style={{margin:"0 auto 1rem auto"}}><img alt='Remote Monitoring and Management' src='/services/remote-monitoring.svg' style={{width:"10rem"}} /></div>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
            <Typography variant='h2' fontSize={22} style={{textAlign:"center", padding:"", /* textWrap:isPhone ? "wrap" : "nowrap" */}}>
                Remote Monitoring & Management
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                We will proactively monitor your network and everything on it using our Remote Monitoring and Management (RMM) system. We will let you know if something looks out of the ordinary and our Support Team will fix it; because after all, we love this stuff! Hard drive out of space? Internet down? Virus or malware on your PC? No matter what the issue, our system allows us to deliver proactive IT support and save your business time and money. Our system will do its best to resolve the issues by itself using its configured scripts and fixes without even needing our support staff. And, the best part is it’s all included with our monthly services!
                <p id='on-site-it-network-services' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
              <div style={{margin:"1rem auto"}}><img alt='On-Site Computer Support' src='/services/on-site-support.svg' style={{width:"10rem"}} /></div>
              <Typography variant='h2' fontSize={22} style={{textAlign:"center"}}>
                On-Site IT & Network Services
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                We think of ourselves as your IT Department, not an outsourced company that is disengaged from your business. Our experienced team of professional, patient and courteous technicians and engineers love technology. We will learn your business and processes to provide the best support possible. It is our job to make your lives easier when it comes to technology. We can be nosy when it comes to our clients’ operations because if we don’t know how they do business, we can’t provide the best technology advice.
                <p id='windows-patching-updates' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
              <div style={{margin:"1rem auto"}}><img alt='Windows Patching and Updates' src='services/windows-patching.svg' style={{width:"10rem"}} /></div>
              <Typography variant='h2' fontSize={22} style={{textAlign:"center"}}>
                Windows Updates & 3rd Party Patching
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Are you installing Windows updates and patches manually? Once our engineers test and approve Windows patches and updates, our Remote Monitoring and Management (RMM) system proactively installs the updates behind the scenes. This ensures that all computers and servers are constantly being updated and stay as secure as possible. Our team will also update any Line of Business (LOB) applications whenever new versions or updates are released so that you are always up-to-date and in compliance! 
                <p id='business-technology-audit' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
              <div style={{margin:"1rem auto"}}><img alt='Business Technology Review' src='/services/technology-business-review.svg' style={{width:"10rem"}} /></div>
              <Typography variant='h2' fontSize={22} style={{textAlign:"center"}}>
                Business Technology Audit
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Our job is to ensure that our clients use technology to gain a competitive advantage in their industry. We love meeting with clients to learn how we can help them work smarter and increase efficiency. We do not deliver cookie-cutter solutions. We customize each client’s solution based on their budget, needs and prospective growth. We meet with our clients often to ensure we are informed about their IT and operational goals so that we can strategize the best way to facilitate those changes.
                <p id='it-analytics-reporting' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
              <div style={{margin:"1rem auto"}}><img alt='IT Analytics Report' src='/services/it-analytics-reporting.svg' style={{width:"10rem"}} /></div>
              <Typography variant='h2' fontSize={22} style={{textAlign:"center"}}>
                Analytics & Reporting
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                We can run all kinds of reports about our support and the network environment. Need to see how many service requests were created for the month? Want to see how many computers are in the environment with certain specs? Reporting allows us to extract data so that you can make informed decisions about IT operations, purchasing, service trends, etc. Let’s take a look at how we can use the data to provide even more effective IT support.
              </Typography>
            </div>
          </div>
        </div>
      </>
      }
    </div>

    )
  }


export default ManagedServices