import { tokens } from '../theme'
import { useMediaQuery, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { FaYelp } from "react-icons/fa"
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import '../index.css'

const Social = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))

  return (
    <div style={{display:"flex", flexDirection:"column", textAlign:"left", paddingRight:isMobile ? "0" : "1rem", gap:"1rem", scale:"100%"}}>
      <div style={{margin:"0 0 0 0.5rem"}}>
        <Link to="/" role="link" aria-label='go-to-home'>
          <img style={{height:"3.3rem"}}src="/logo-7l-full.webp" alt='7 Layer IT Solutions Logo'/>
        </Link>
      </div>
      <div style={{marginTop:"1rem", justifyContent:"center", margin:"0 auto"}}>
        <p style={{color:colors.grey[100], fontFamily:"Inter", fontWeight:700, fontSize:17}}>CONNECT WITH US</p>
        <div style={{display:"flex", flexDirection:"row",margin:"0 auto 0 0", paddingTop:"0.6rem", gap:"0.5rem"}}>
          <div><a href="https://www.linkedin.com/company/7lits" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><FaLinkedin className="social" style={{ fontSize: 36, fill:colors.grey[400] }} /></a></div>
          <div><a href="https://www.facebook.com/7lits" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><FaFacebookSquare className="social" style={{ fontSize: 36, fill:colors.grey[400] }} /></a></div>
          <div><a href="https://www.instagram.com/7lits" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><FaInstagramSquare className="social" style={{ fontSize: 36, fill:colors.grey[400]}} /></a></div>
          <div><a href="https://www.yelp.com/biz/7-layer-it-solutions-chatsworth" target="_blank" rel="noopener noreferrer" aria-label="Yelp"><FaYelp className="social" style={{ fontSize: 30, fill:colors.grey[400], marginTop:"0.2rem"}} /></a></div>
        </div>
      </div>
    </div>
  )
}

export default Social