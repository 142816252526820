import { Link } from 'react-router-dom'
import { Box, Fab, Typography, useMediaQuery, useTheme } from '@mui/material'
import { tokens } from '../../theme'
import ScrollTop from '../../components/buttons/ScrollToTop'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CableIcon from '@mui/icons-material/Cable'
import { IoMdCloudOutline } from "react-icons/io";
import { FaDownload } from "react-icons/fa6"
import { FaPhoneVolume } from "react-icons/fa6"
import { FaPrint } from "react-icons/fa6"
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined'
import WifiPasswordOutlinedIcon from '@mui/icons-material/WifiPasswordOutlined'
import '../../index.css'

const ITSolutions = (props) => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const colors = tokens(mode)
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  const isPhone = useMediaQuery(theme.breakpoints.down('tablet'))
  const isTablet = !isPhone && !isDesktop

  return (
    <div className='services' style={{
      display:"flex",
      flexDirection:"column",
      overflow:"hidden",
      width:"100%",
      margin:0
    }}>
      {isDesktop ? <>
        <div style={{display:"flex", flexDirection:"column", textAlign:"center"}}>
          <div>
            <h1 style={{fontSize:"2rem"}}>
              IT Solutions
            </h1>
            <div style={{margin:"2rem 12rem", textAlign:"center"}}>
              <Typography variant="p" fontSize={22} fontWeight="normal">
                We strive to provide our clients with exceptional and cutting-edge IT solutions at affordable prices. We will design, implement and manage secure, reliable and cost-effective networks whether the client has two or 2,000 users. We will always maintain that personal connection with our clients and our dependability will be second to none.
              </Typography>
            </div>
          </div>
        </div>
        <div style={{width:"90%", margin:"2rem auto", height:"1rem",backgroundColor:colors.green['primary'], opacity:0.7, verticalAlign:"top"}}></div>
        <div className='it-services-nav' style={{margin:"2rem 1rem 0 1rem", fontSize:17, textAlign:"center", display:"inline-flex", gap:"2rem", justifyContent:"center"}}>
          <Link to='#cloud-services'>
            <Box sx={{'&:hover': {color:"#48a842"},color:colors.primary[500],  borderRadius:"10px"}}>
              <IoMdCloudOutline fontSize={40} style={{opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Cloud7<br></br>Services</Typography>
            </Box>
          </Link>
          <Link to='#backup-disaster-recovery'>
            <Box sx={{'&:hover': {color:"#48a842"},color:colors.primary[500], borderRadius:"10px"}}>
              <FaDownload fontSize={32} style={{marginBottom:"0.2rem", opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Business Continuity<br></br>Disaster Recovery</Typography>
            </Box>
          </Link>
          <Link to='#voip'>
            <Box sx={{'&:hover':{color:"#48a842"}, color:colors.primary[500], borderRadius:"10px"}}>
              <FaPhoneVolume fontSize={33} style={{marginBottom:"0.4rem", opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Voice over Internet<br></br>Protocol (VoIP)</Typography>
            </Box>
          </Link>
          <Link to='#managed-print-services'>
            <Box sx={{'&:hover': {color:"#48a842"}, color:colors.primary[500], borderRadius:"10px"}}>
              <FaPrint fontSize={35} style={{marginBottom:"0.2rem", opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Managed Print<br></br>Services (MPS)</Typography>
            </Box>
          </Link>
          <Link to='#internet-connectivity'>
            <Box sx={{'&:hover': {color:colors.green['primary']}, color:colors.primary[500], borderRadius:"10px"}}>
              <WifiPasswordOutlinedIcon style={{fontSize:38, marginBottom:"0.2rem", opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Internet<br></br>Connectivity</Typography>
            </Box>
          </Link>
          <Link to='#structured-cabling-electrical'>
            <Box sx={{'&:hover': {color:colors.green['primary']}, color:colors.primary[500], borderRadius:"10px"}}>
              <CableIcon style={{fontSize:38, opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Structured Cabling<br></br> & Electrical</Typography>
            </Box>
          </Link>
          <Link to='#technology-procurement'>
            <Box sx={{'&:hover':{color:colors.green['primary']}, color:colors.primary[500], borderRadius:"10px"}}>
              <DevicesOutlinedIcon style={{fontSize:38, opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Technology<br></br>Procurement</Typography>
            </Box>
          </Link>
        </div>
        <div id='cloud-services' className='service' style={{position:"relative"}}>
          <div style={{display:'flex', flexDirection:"row", margin:"9rem 15rem 5rem 15rem"}}>
            <div style={{margin:"auto 2rem auto 0"}}><img alt='Cloud Services' src='/services/cloud-solutions.svg' style={{width:"20rem"}} /></div>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 0"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Cloud7 Services
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Moving applications and infrastructure to the Cloud has become very popular. There are many factors to consider when deciding if it is the right move for your company – private, public, vendor, cost, location, specifications, uptime guarantees, etc. We have leveraged our resources to create our own Private Cloud that we call Cloud7.<br></br><br></br>
                With over ten internet service providers, redundant power on separate physical hardware all the way down to the server rack, state-of-the art cooling, power generators, and many levels of security, this facility can securely house and host your data as close to 100% as you can get! Ask us how we can help you migrate your servers and data over with almost zero downtime!<br></br><br></br>
                Have multiple sites and looking for centralized application and data access? Don’t want the headaches and costs of purchasing and configuring infrastructure hardware and storing at your office? Let us connect you into our Cloud! Let us assess your technology and see if it is the best move for your business.
                <p id='backup-disaster-recovery' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div style={{display:'flex', flexDirection:"row", margin:"5rem 15rem"}}>
            <div style={{display:"flex", flexDirection:"column", marginTop:"1rem"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Business Continuity & Disaster Recovery
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Your data is the most critical part of your business. So why don’t you care for it that way? Can you really depend on your current backup solution? Are you still running one daily backup? Have you tried to restore it? Is there anyone even checking on your backups? If you had a server or network outage, do you know how long you would really be out of business?
                <br></br><br></br>Downtime is not an option for companies today. Different types of issues can occur and can impact your revenue and possibly your business existence. Whether it’s a security breach such as Ransomware or Phishing attack, power outage, fire, or simply a human mistake, these events are unpredictable. The best thing you can do is to BE PREPARED! Preparedness means that you should have a solid BCDR plan that has been tested and that can be activated smoothly. Do you know what your Recovery Point Objective (RPO) and Recovery Time Objective (RTO) times are? If not, you should contact us so we can help you put a comprehensive plan in place.
                <br></br><br></br>We image our clients’ servers multiple times per day. We send our backup images to offsite, geo-redundant, encrypted datacenters compliant with the Service Organization Control (SOC 1/ SSAE 16 and SOC 2) reporting standards. If your server fails, we will have you back up in minutes, not days. Our monitoring system will notify us if/when a backup job fails and our technicians will fix it right away. Need a file, folder or server restored, we got it! We promise to take care of your data as if it were our own. Because even if you don’t realize how important your data is, we do!
                <br></br><br></br>Our BCDR solutions enable us to bring your network operations up in the cloud in the event of a catastrophic failure and we can do it quickly. If your business can’t afford to turn off its Open sign, we should talk.
                <p id='voip' style={{marginBottom:"1rem", lineHeight:1}}></p>
              </Typography>
            </div>
            <div style={{margin:"auto 0 auto 0"}}><img alt='Business Continuity and Disaster Recovery' src='/services/disaster-recovery.svg' style={{width:"21rem"}} /></div>
          </div>
          <div style={{display:'flex', flexDirection:"row", margin:"5rem 15rem"}}>
            <div style={{margin:"auto 2rem auto 0"}}><img alt='VoIP Services' src='/services/voip.svg' style={{width:"20rem"}} /></div>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 0"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Voice over Internet Protocol (VoIP)
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Voice over Internet Protocol (VoIP) is a technology that uses the internet to make and receive phone calls. When setup properly, a true VoIP system could save your business up to 75% on phone bills.
                <br></br><br></br>Our VoIP services include a mobile application to give you all the benefits of having your office phone in your pocket. You’ll no longer need to share your mobile phone number with clients and vendors. Connect multiple offices together and transfer calls for free instead of paying the phone company. The desktop application displays who is on the phone, with who and for how long, and you can send them a private message while still on the call! Send and receive faxes electronically to increase security and cut costs. These are just a few of the many features included with a true Voice over IP solution.
                <br></br><br></br>Contact us to learn how you can save on phone bills and love your phone system again!
                <p id='managed-print-services' style={{marginBottom:"1rem", lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div style={{display:'flex', flexDirection:"row", margin:"5rem 15rem"}}>
            <div style={{display:"flex", flexDirection:"column", marginTop:"1rem"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Managed Print Services (MPS)
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Printing, copying, faxing, desktop printers, multifunction (MFP) devices, scanners, paper, ink, toner, supplies and support can cost up to 15% of a company’s annual spending. Consolidating those services into a single vendor can save your company around 30% and remove all the headaches of managing that part of your business. Our Managed Print Services (MPS) partners can provide solutions with all the major copy and print companies in the industry such as HP, Xerox, Brother, Konica Minolta and Ricoh. Let us assess your usage and current devices to come up with a solution that will save you money and consolidate the equipment and services.
                <p id='internet-connectivity' style={{marginBottom:"1rem", lineHeight:1}}></p>
              </Typography>
            </div>
            <div style={{margin:"auto 0 auto 0"}}><img alt='Managed Print Services' src='/services/managed-print-services.svg' style={{width:"20rem"}} /></div>
          </div>
          <div style={{display:'flex', flexDirection:"row", margin:"5rem 15rem"}}>
            <div style={{margin:"auto 2rem auto 0"}}><img alt='Internet Connectivity' src='/services/secure-business-internet.svg' style={{width:"20rem"}} /></div>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 0"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Internet Connectivity
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Are you moving offices or need additional bandwidth but don’t want to waste a bunch of time figuring out what to get and who to get it from? With all the connectivity options and technologies available today, deciding which is the best option for your business can be very difficult and confusing. We partner with one of the country’s largest technology Master Agents. This allows us to source pricing on any internet technology, from any vendor and at any location. With our many years of experience, we can tell you which solution is best for your business. You will feel confident that you are getting the best solution at the best price.
                <p id='structured-cabling-electrical' style={{marginBottom:"1rem", lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div style={{display:'flex', flexDirection:"row", margin:"5rem 15rem"}}>
            <div style={{display:"flex", flexDirection:"column", marginTop:"1rem"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Structured Cabling & Electrical
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Setting up a new office or expanding your current one? Whether you need Cat 3, Cat 5e, Cat 6, Cat 7, Fiber or Audio/Video, our team can design and implement a network cabling and electrical solution specifically tailored for your office needs. With our longstanding relationships in the industry, we can deploy and manage these projects so you know it will be done correctly, the first time.
                <p id='technology-procurement' style={{marginBottom:"1rem", lineHeight:1}}></p>
              </Typography>
            </div>
            <div style={{margin:"auto 0 auto 0"}}><img alt='Structured Cabling and Electrical Services' src='/services/structured-cabling.svg' style={{width:"20rem"}} /></div>
          </div>
          <div style={{display:'flex', flexDirection:"row", margin:"5rem 15rem"}}>
            <div style={{margin:"auto 2rem auto 0"}}><img alt='Technology Procurement' src='/services/technology-procurement.svg' style={{width:"20rem"}} /></div>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 0"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Technology Procurement
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Purchasing technology hardware, software, licensing, etc. can be very time-consuming and tricky. If you don’t know the right technologies or don’t have the right relationships, you’ll end up spending more than you need and wasting a bunch of time.
                Let us be the purchasing department for your company. Simply let us know what you are looking for and let us do all the footwork. We have partnerships with the best manufacturers and distributors in technology and can get anything from one PC to an entire office solution. Our sales team will recommend the right fit for your needs without overselling you.
              </Typography>
            </div>
          </div>
      </div>
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      </>
      :
      <>
        <div style={{display:"flex", flexDirection:"column", textAlign:isPhone ? "left" : "center"}}>
          <div style={{margin:"1rem 2rem"}}>
            <h1 style={{fontSize:"1.5rem", margin:"0 2rem"}}>
              IT Solutions
            </h1>
            <div style={{margin:isDesktop ? "2rem 3rem" : "2rem", textAlign:isPhone ? "left" : "center"}}>
              <Typography variant="p" fontSize={22} fontWeight="normal">
                We strive to provide our clients with exceptional and cutting-edge IT solutions at affordable prices. We will design, implement and manage secure, reliable and cost-effective networks whether the client has two or 2,000 users. We will always maintain that personal connection with our clients and our dependability will be second to none.
              </Typography>
            </div>
          </div>
          <p id='cloud-services' style={{marginBottom:"1rem", lineHeight:1}}></p>
        </div>
      <div className='service' style={{margin:"2rem 0", position:"relative"}}>
        <div style={{display:'flex', margin:isTablet? "4rem" : "2rem"}}>
          <div style={{display:"flex", flexDirection:"column", marginTop:"1rem"}}>
            <div style={{margin:"1rem auto"}}><img alt='Cloud Services' src='/services/cloud-solutions.svg' style={{width:"10rem"}} /></div>
            <Typography variant='h2' fontSize={22} style={{textAlign:"center", padding:"", textWrap:isPhone ? "wrap" : "nowrap"}}>
              Cloud7 Services
            </Typography>
            <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
              Moving applications and infrastructure to the Cloud has become very popular. There are many factors to consider when deciding if it is the right move for your company – private, public, vendor, cost, location, specifications, uptime guarantees, etc. We have leveraged our resources to create our own Private Cloud that we call Cloud7.<br></br><br></br>
              With over ten internet service providers, redundant power on separate physical hardware all the way down to the server rack, state-of-the art cooling, power generators, and many levels of security, this facility can securely house and host your data as close to 100% as you can get! Ask us how we can help you migrate your servers and data over with almost zero downtime!<br></br><br></br>
              Have multiple sites and looking for centralized application and data access? Don’t want the headaches and costs of purchasing and configuring infrastructure hardware and storing at your office? Let us connect you into our Cloud! Let us assess your technology and see if it is the best move for your business.
              <p id='backup-disaster-recovery' style={{lineHeight:1}}></p>
            </Typography>
          </div>
        </div>
        <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
          <div style={{margin:"1rem auto"}}><img alt='Business Continuity and Disaster Recovery' src='/services/disaster-recovery.svg' style={{width:"10rem"}} /></div>
          <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
            <Typography variant='h2' fontSize={22} style={{textAlign:"center", padding:"", /* textWrap:isPhone ? "wrap" : "nowrap" */}}>
              Business Continuity & Disaster Recovery
            </Typography>
            <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
              Your data is the most critical part of your business. So why don’t you care for it that way? Can you really depend on your current backup solution? Are you still running one daily backup? Have you tried to restore it? Is there anyone even checking on your backups? If you had a server or network outage, do you know how long you would really be out of business?
              <br></br><br></br>Downtime is not an option for companies today. Different types of issues can occur and can impact your revenue and possibly your business existence. Whether it’s a security breach such as Ransomware or Phishing attack, power outage, fire, or simply a human mistake, these events are unpredictable. The best thing you can do is to BE PREPARED! Preparedness means that you should have a solid BCDR plan that has been tested and that can be activated smoothly. Do you know what your Recovery Point Objective (RPO) and Recovery Time Objective (RTO) times are? If not, you should contact us so we can help you put a comprehensive plan in place.
              <br></br><br></br>We image our clients’ servers multiple times per day. We send our backup images to offsite, geo-redundant, encrypted datacenters compliant with the Service Organization Control (SOC 1/ SSAE 16 and SOC 2) reporting standards. If your server fails, we will have you back up in minutes, not days. Our monitoring system will notify us if/when a backup job fails and our technicians will fix it right away. Need a file, folder or server restored, we got it! We promise to take care of your data as if it were our own. Because even if you don’t realize how important your data is, we do!
              <br></br><br></br>Our BCDR solutions enable us to bring your network operations up in the cloud in the event of a catastrophic failure and we can do it quickly. If your business can’t afford to turn off its Open sign, we should talk.
              <p id='voip' style={{lineHeight:1}}></p>
            </Typography>
          </div>
        </div>
        <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
          <div style={{display:"flex", flexDirection:"column", marginTop:"1rem"}}>
            <div style={{margin:"1rem auto"}}><img alt='VoIP Services' src='/services/voip.svg' style={{width:"10rem"}} /></div>
            <Typography variant='h2' fontSize={22} style={{textAlign:"center"}}>
              Voice over Internet Protocol (VoIP)
            </Typography>
            <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
              Voice over Internet Protocol (VoIP) is a technology that uses the internet to make and receive phone calls. When setup properly, a true VoIP system could save your business up to 75% on phone bills.
              <br></br><br></br>Our VoIP services include a mobile application to give you all the benefits of having your office phone in your pocket. You’ll no longer need to share your mobile phone number with clients and vendors. Connect multiple offices together and transfer calls for free instead of paying the phone company. The desktop application displays who is on the phone, with who and for how long, and you can send them a private message while still on the call! Send and receive faxes electronically to increase security and cut costs. These are just a few of the many features included with a true Voice over IP solution.
              <br></br><br></br>Contact us to learn how you can save on phone bills and love your phone system again!
              <p id='managed-print-services' style={{lineHeight:1}}></p>
            </Typography>
          </div>
        </div>
        <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
          <div style={{display:"flex", flexDirection:"column", margin:"auto 0"}}>
            <div style={{margin:"1rem auto"}}><img alt='Managed Print Services' src='/services/managed-print-services.svg' style={{width:"10rem"}} /></div>
            <Typography variant='h2' fontSize={22} style={{textAlign:"center"}}>
              Managed Print Services (MPS)
            </Typography>
            <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
              Printing, copying, faxing, desktop printers, multifunction (MFP) devices, scanners, paper, ink, toner, supplies and support can cost up to 15% of a company’s annual spending. Consolidating those services into a single vendor can save your company around 30% and remove all the headaches of managing that part of your business. Our Managed Print Services (MPS) partners can provide solutions with all the major copy and print companies in the industry such as HP, Xerox, Brother, Konica Minolta and Ricoh. Let us assess your usage and current devices to come up with a solution that will save you money and consolidate the equipment and services. 
              <p id='internet-connectivity' style={{lineHeight:1}}></p>
            </Typography>
          </div>
        </div>
        <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
          <div style={{display:"flex", flexDirection:"column", margin:"auto 0"}}>
            <div style={{margin:"1rem auto"}}><img alt='Internet Connectivity' src='/services/secure-business-internet.svg' style={{width:"10rem"}} /></div>
            <Typography variant='h2' fontSize={22} style={{textAlign:"center"}}>
              Internet Connectivity
            </Typography>
            <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
              Are you moving offices or need additional bandwidth but don’t want to waste a bunch of time figuring out what to get and who to get it from? With all the connectivity options and technologies available today, deciding which is the best option for your business can be very difficult and confusing. We partner with one of the country’s largest technology Master Agents. This allows us to source pricing on any internet technology, from any vendor and at any location. With our many years of experience, we can tell you which solution is best for your business. You will feel confident that you are getting the best solution at the best price.
              <p id='structured-cabling-electrical' style={{lineHeight:1}}></p>
            </Typography>
          </div>
        </div>
        <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
          <div style={{display:"flex", flexDirection:"column", margin:"auto 0"}}>
            <div style={{margin:"1rem auto"}}><img alt='Structured Cabling and Electrical Services' src='/services/structured-cabling.svg' style={{width:"10rem"}} /></div>
            <Typography variant='h2' fontSize={22} style={{textAlign:"center"}}>
              Structured Cabling & Electrical
            </Typography>
            <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
              Setting up a new office or expanding your current one? Whether you need Cat 3, Cat 5e, Cat 6, Cat 7, Fiber or Audio/Video, our team can design and implement a network cabling and electrical solution specifically tailored for your office needs. With our longstanding relationships in the industry, we can deploy and manage these projects so you know it will be done correctly, the first time.
              <p id='technology-procurement' style={{lineHeight:1}}></p>
            </Typography>
          </div>
        </div>
        <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
          <div style={{display:"flex", flexDirection:"column", margin:"auto 0"}}>
            <div style={{margin:"1rem auto"}}><img alt='Technology Procurement' src='/services/technology-procurement.svg' style={{width:"10rem"}} /></div>
            <Typography variant='h2' fontSize={22} style={{textAlign:"center"}}>
              Technology Procurement
            </Typography>
            <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
              Purchasing technology hardware, software, licensing, etc. can be very time-consuming and tricky. If you don’t know the right technologies or don’t have the right relationships, you’ll end up spending more than you need and wasting a bunch of time.
              Let us be the purchasing department for your company. Simply let us know what you are looking for and let us do all the footwork. We have partnerships with the best manufacturers and distributors in technology and can get anything from one PC to an entire office solution. Our sales team will recommend the right fit for your needs without overselling you.
            </Typography>
          </div>
        </div>
      </div>
    </>}
    </div>
    )
  }


export default ITSolutions