import { useCallback, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { tokens } from '../../theme'
import { menuItems } from '../../static/menuItems'
import Action from '../buttons/Action'
import './index.css'

function getMenuLastIndex() {
  let indexes = {}
  menuItems.forEach(function(menu) {
    indexes[menu.title] = menu.children.length - 1
  })
  return indexes
}

export default function Menu() {
  const theme = useTheme()
  const mode = theme.palette.mode
  const colors = tokens(mode)
  const isLaptop = useMediaQuery(theme.breakpoints.up('laptop'))

  const lastIndex = getMenuLastIndex()
  const [menus, setMenus] = useState({})

  const handleOpen = useCallback(e => {
    let updatedMenus = {}
    menuItems.forEach(function(menu) {
      if (menu.title === e.target.innerText) {
        updatedMenus[menu.title] = true
      } else {
        updatedMenus[menu.title] = false
      }
    })
    setMenus(updatedMenus)
  },[])
  
  const handleClose = useCallback(() => {
    let updatedMenus = {}
    menuItems.forEach(function(menu) {
      updatedMenus[menu.title] = false
    })
    setMenus(updatedMenus)
  },[])

  const dropMenu = useRef()
  window.addEventListener('click', (e) => {
    if (e.target !== dropMenu.current) { handleClose }
  })

  return (
    <header id='header' className='menu-container'>
      <div className='menu'>
        <div>
          <Link to="/" onFocus={handleClose} aria-label='go-to-home'>
            <img className='logo' src="/logo-7l-full.webp" alt='7 Layer IT Solutions Logo'/>
          </Link>
        </div>
        <nav>
          <ul className='nav-links' style={{display:"inline-flex", padding:isLaptop ? "1.5rem 2rem 0 2rem" : "0 1rem"}}>
            {menuItems.map(({ title, path, aria, children }, index) => {
              return (
                <li key={index} style={{padding:"0 1.5rem"}} >
                  <div style={{height:"230%"}} onMouseEnter={e => handleOpen(e)} onMouseLeave={handleClose}>
                    <NavLink aria-label={aria} to={path} className={menus[title] ? 'active-link nav-link' : 'nav-link'} onClick={handleClose} onFocus={handleOpen}>
                      <Typography variant='headerMenu'>{title}</Typography>
                    </NavLink>
                    {menus[title] && <ul ref={dropMenu} role='menu' id={aria} className='drop-menu' style={{backgroundColor:colors.grey[900]}} >
                      {children.map(({ subtitle, path, icon, aria }, idx) => (
                        <li key={idx} className='nav-item'>
                          <Link to={path} aria-label={aria} onClick={handleClose}>
                            <div className={idx === 0 ? "nav-item-div-first" : idx === lastIndex[title] ? "nav-item-div-last" : "nav-item-div"}>
                              <div style={{marginTop:"0.4rem"}}>{icon}</div>
                              <Typography variant='menuItem'>{subtitle}</Typography>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>}
                </div>
              </li>
              );
            })}
          </ul>
        </nav>
        <div className='box' onFocus={handleClose}>
          <Action
            to='/contact'
            text='Contact Us'
            isDesktop={isLaptop}
            colors={colors}
            mode={mode}
            aria="Contact Us"
          />
        </div>
      </div>
    </header>
  )
}
