import PropTypes from 'prop-types'
import { clientList } from '../static/clientList'
import '../index.css'

const Clients = ({ isDesktop }) => {
  return (
    <div className='client-container'>
      <div style={{marginTop:"4rem", textAlign:"center"}}>
      <h2 style={{fontSize:isDesktop ? 32 : 24}}>Clients</h2>
        <div style={{padding:"1.2rem 3rem"}}>
          <h3 style={{fontSize:"1.25rem", fontWeight:700}}>
            We provide exceptional, cutting-edge IT solutions at affordable prices.<br></br>
            Whether you have two or 2,000 users, our dependability is second to none, just ask our clients.
          </h3>
        </div>
      </div>
        <div className='logos'>
          <div className='logos-slide'>
            {clientList.map((clientInfo, index) => {
              return (
                <img key={index} src={clientInfo.image} style={clientInfo.style} alt={clientInfo.label + ' Logo'}/>
              )
            })}
          </div>
          <div className='logos-slide'>
            {clientList.map((clientInfo, index) => {
              return (
                <img key={index} src={clientInfo.image} style={clientInfo.style} alt={clientInfo.label + ' Logo'}/>
              )
            })}
          </div>
        </div>
    </div>
  )
}

Clients.propTypes = {
  isDesktop: PropTypes.bool.isRequired
}

export default Clients



