import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import Action from '../buttons/Action'
import './index.css'

const VideoOverlay = ({ colors }) => {
  const slides = [
    {
      description: ['MANAGED IT SERVICES'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/managed-it-services',
      aria:'managed-it-services'
    },
    {
      description: ['CLOUD7 SOLUTIONS'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/it-solutions#cloud-services',
      aria:'cloud-services'
    },
    {
      description: ['CYBERSECURITY'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/network-and-cybersecurity',
      aria:'network-and-cybersecurity'
    },
    {
      description: ['BUSINESS CONTINUITY &','DISASTER RECOVERY'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/it-solutions#backup-disaster-recovery',
      aria:'business-continuity-and-disaster-recovery'
    },
  ]

  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    let handler = () => {
      let ct = video.currentTime
      if (ct > 7 && ct <= 14) {
        setCurrentIndex(1)
      } else if (ct > 14 && ct <= 21) {
        setCurrentIndex(2)
      } else if (ct > 21 && ct <= 31) {
        setCurrentIndex(3)
      } else {
        setCurrentIndex(0)
      }
    }

    let video = document.querySelector("video");
    video.addEventListener("timeupdate", handler)
  })

  return (    
      <div className={'overlay_' + currentIndex}>
        <Link to={slides[currentIndex].to}>
          <Box sx={{display:"flex",flexDirection:"column", textAlign:"center", margin:"auto", lineHeight:"4rem"}}>
            {slides[currentIndex].description.map((text, textIndex) => {
              return (
                <div className='overlay_title' key={textIndex}>{text}</div>
              )})}
            <div style={{display:"flex", margin:"1.5rem auto 0 auto"}}>
              <Action text={slides[currentIndex].buttonText} colors={colors} isDesktop={true} aria={slides[currentIndex].aria}/>
            </div>
          </Box>
        </Link>
      </div>
  )
}

VideoOverlay.propTypes = {
  colors: PropTypes.object.isRequired
}

export default VideoOverlay