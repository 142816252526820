import { Fab, useMediaQuery, useTheme } from '@mui/material'
import { tokens } from '../../theme'
import Clients from '../../components/Clients'
import ScrollTop from '../../components/buttons/ScrollToTop'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ManagedServices from '../../components/ManagedServices'
import Partners from '../../components/Partners'
import Video from '../../components/Video'
import Carousel from '../../components/Carousel'

const Home = (props) => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const colors = tokens(mode)
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))

  const slides = [
    {
      className:'img-managed-it-services',
      url:`/managed-it-services-los-angeles-first-frame.webp`,
      description: ['MANAGED IT SERVICES'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/managed-it-services',
      alt:'Managed IT Services'
    },
    {
      className:'img-cloud-services-los-angeles',
      url:`/cloud-services-los-angeles.jpg`,
      description: ['CLOUD7 SOLUTIONS'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/managed-it-services#cloud-services',
      alt:'Cloud Services'
    },
    {
      className:'img-cybersecurity fade-in-image',
      url:`/cybersecurity-los-angeles.jpg`,
      description: ['CYBERSECURITY'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/network-and-cybersecurity',
      alt:'Cybersecurity'
    },
    {
      className:'img-disaster-recovery fade-in-image',
      url:`/business-data-recovery-los-angeles.jpg`,
      description: ['BUSINESS CONTINUITY &','DISASTER RECOVERY'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/managed-it-services',
      alt:'Business Continuity and Disaster Recovery'
    },
  ]

  return (
    <div style={{
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center",
      overflow:"hidden"
    }}>
      {isDesktop
        ? <Video colors={colors} />
        : <Carousel slides={slides} isDesktop={isDesktop} />
      }
      <ManagedServices />
      <Clients isDesktop={isDesktop} />
      <Partners isDesktop={isDesktop} colors={colors} mode={mode}/>
      {isDesktop && <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>}
      </div>
  )
}

export default Home