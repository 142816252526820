import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { tokens } from '../theme'
import CopyrightIcon from '@mui/icons-material/Copyright'
/* import ColorMode from './ColorMode' */

const Terms = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))

  return (
    <div style={{display:"flex", flexDirection:"column", margin:"2rem auto 0 auto", justifyContent:"center"}}>
      {/* <ColorMode /> */}
      <div style={{padding:0, margin:0, display:"flex", flexDirection:"row", gap:"0.4rem", color:colors.grey[300], textAlign:"center"}}>
        <Typography variant="p" color={colors.grey[300]} style={{fontSize:isMobile ? 10 : 14}}>7 Layer IT Solutions, Inc.</Typography>
        <CopyrightIcon style={{fontSize:isMobile ? 12 : 16, marginTop:"0.15rem"}} />
        <Typography variant="p" style={{fontSize:isMobile ? 10 : 14}}>2024 All Rights Reserved</Typography>
      </div>
    </div>
  )
}

export default Terms