import { Link, NavLink } from 'react-router-dom'
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material'
import { tokens } from '../theme'
import Terms from './Terms'
import Social from './Social'
import '../index.css'
import { menuItems } from '../static/menuItems'

const Footer = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const isLaptop = useMediaQuery(theme.breakpoints.up('laptop'))

  return (
    <footer id='footer'>
      <Box sx={{
        maxWidth:"100%",
        display:"flex",
        flexDirection:"row",
        backgroundColor:colors.grey[900],
        justifyContent:"center",
        p:"3rem 1rem 0 0.5rem",
        m:"0 auto",
        '& ul':{ padding:0, margin:0 },
        '& li': { padding:"0" },
        '& svg:hover path': {
          backgroundColor:"inherit",
          color:colors.green['primary'],
          fill:colors.green['primary']
        },
      }}>

        <nav style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
          <Social />
          {isLaptop && 
          <div style={{display:"inline-flex", gap:"1rem"}}>
            {menuItems.map(({ title, path, aria, children }, index) => {
              return (
                <div key={index} style={{display:"flex", flexDirection:"row"}}>
                  <Divider aria-hidden="true" orientation="vertical" flexItem />
                  <div style={{margin:"0 0.2rem 0 1.2rem"}}>
                    <NavLink aria-label={aria} to={path} className={({ isActive }) => (isActive ? 'active-link nav-link' : 'nav-link')}>
                      <Typography variant='footerMenu'>{title}</Typography>
                    </NavLink>
                    <ul style={{display:"flex", flexDirection:"column"}}>
                      {children.map(({ subtitle, path, aria }, idx) => {
                        return (
                          <li key={idx}><Link className='footer-link' aria-label={aria} to={path}><Typography variant='footerMenuItem'>{subtitle}</Typography></Link></li>
                        )
                      })}
                      {title === 'Company' && <li style={{marginTop:"0.5rem"}}><Link aria-label='careers' to='/careers'><img alt='Great Place To Work Badge' src='7_Layer_IT_Solutions_US_English_2024_Certification_Badge.svg'/></Link></li>}
                    </ul>
                  </div>
                </div>
              )
            })}
            </div>}
        </nav>
      </Box>
      <div style={{backgroundColor:colors.grey[900], display:"flex", alignItems:"center",flexDirection:"row", justifyContent:"center", padding:"0 0 1rem 0"}}>
        <Terms />
      </div>
    </footer>
  )
}

export default Footer