import { Accordion, AccordionSummary, AccordionDetails, Typography, useTheme } from '@mui/material'
import { tokens } from '../../theme'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Action from '../../components/buttons/Action'

const Level1Support = ({ isDesktop, expanded, onAccordionChange }) => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const colors = tokens(mode)

  return (
    <Accordion
      disableGutters
      expanded={expanded === 'level-1-support'}
      onChange={(e) => onAccordionChange(e,'level-1-support')}
      sx={{".css-1pvvkxv-MuiButtonBase-root-MuiAccordionSummary-root":{p:!isDesktop ? 0 : ''},"&.MuiAccordion-root:before": {backgroundColor: "#fcfcfc"}, backgroundColor:"#fcfcfc", boxShadow: "none", borderTop:" 1px solid #ededed", borderBottom:" 1px solid #ededed"}}
      slotprops={{ transition: { unmountOnExit: true } }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-controls="level-1-support-content"
        id="level-1-support-header"
      >
        <h3 style={{marginRight:"1rem", padding:isDesktop ? "1rem 0" : 0}}>Level I Technical Support Analyst</h3>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{position:isDesktop ? "absolute" : "relative", right:isDesktop ? 0 : '', top:isDesktop ? "5rem" : '', marginBottom:!isDesktop ? "2.2rem" : ""}}><Action target='_blank' text='Apply Now' to='https://recruiting.paylocity.com/Recruiting/Jobs/Apply/2380615' colors={colors} isDesktop={true} shadow={true}/></div>
        <h4>Location</h4>
        <Typography variant="p" fontSize={22} fontWeight="normal">Los Angeles, California</Typography>
        <p><br></br></p>
        <h4>Job Type</h4>
        <Typography variant="p" fontSize={22} fontWeight="normal">Full-time</Typography>
        <p><br></br></p>
        <h4>Description</h4>
        <Typography variant="p" fontSize={22} fontWeight="normal">We're a dynamic IT firm specializing in tailored technical support and cutting-edge IT solutions for SMBs across L.A., Ventura, San Bernardino and Orange County. Currently, we're on the lookout for a driven Level II Technical Support Analyst eager to evolve alongside our rapidly expanding team. Join our small yet vibrant group and be part of our exciting growth journey!</Typography>
        <p><br></br></p>
        <h4>Responsibilities</h4>
        <ul style={{listStyle:'circle', marginTop:"0.2rem"}}>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Provide entry-level technical support and troubleshooting for all versions of Windows clients including minor server support.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Troubleshoot and support of Microsoft Active Directory and all related services including Microsoft Office365, SharePoint and related cloud technologies.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Provide assistance with Client Projects ranging from hardware and/or software installations and upgrade deployments, etc.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Provide support for multiple on-premise and cloud-based network environments.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Provide consistent work quality in a fast-paced environment with multiple deadlines.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Create/Update documentation in support of client environments as needed.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Provide timely and accurate progress status on all ongoing support issues, with an emphasis on client satisfaction.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Utilize ticketing system to initiate, track and manage all support issues.</Typography></li>
        </ul>
        <p><br></br></p>
        <h4>Requirements</h4>
        <ul style={{listStyle:'circle', marginTop:"0.2rem"}}>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Minimum of 1 year of experience in Information Technology.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Individual must possess good hardware, operating system and troubleshooting skills.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Strong oral, client relation and written communication skills.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Good time management and organizational skills.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Must be a self-starter and able to multi-task.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Microsoft, Cisco, VMware, Azure certifications are a plus.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Experience with Professional Services Automation (PSA) and Remote Monitoring & Management (RMM) tools.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Highly organized and strong attention to detail.</Typography></li>
        </ul>
        <p><br></br></p>
        <h4>Additional Notes</h4>
        <ul style={{listStyle:'circle', marginTop:"0.2rem"}}>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Employee must have his/her own transportation.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Employee must be available for on-call rotation.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Employee must be available for full-time employment.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Up to 50% travel to on-site clients.</Typography></li>
          <li style={{fontSize:20, margin:"0 1.3rem"}}><Typography variant="p" fontSize={22} fontWeight="normal">Mileage reimbursement for any travel to clients.</Typography></li>
        </ul>
        <p><br></br></p>
        <h4>Benefits</h4>
        <Typography variant="p" fontSize={22} fontWeight="normal">At 7 Layer IT Solutions we are constantly searching for unique ways to enhance the health and happiness of our team members. That is why we offer several benefits programs and onsite amenities. Our benefits include medical, dental, vison, and 401 K plans as well as vacation and sick time. We offer a unique Wellness Reimbursement Program because we are focused on supporting our employees’ physical and mental wellbeing. As a member, you will also have full access to the office amenities including our gym, shower, kitchen, and lounge room.</Typography>
        <p><br></br></p>
        <h4>Salary</h4>
        <Typography variant="p" fontSize={22} fontWeight="normal">$25-30/hour</Typography>
      </AccordionDetails>
    </Accordion>
  )
}

Level1Support.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  expanded: PropTypes.any,
  onAccordionChange: PropTypes.func.isRequired
}

export default Level1Support