import { Link } from 'react-router-dom'
import { Box, Fab, Typography, useMediaQuery, useTheme } from '@mui/material'
import { tokens } from '../../theme'
import ScrollTop from '../../components/buttons/ScrollToTop'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { FaCrosshairs } from "react-icons/fa6" /* EDR */
import { SiSecurityscorecard } from "react-icons/si" /* Vulnerability Management */
import { FaNetworkWired } from 'react-icons/fa' /* SOC */
import { FaUserShield } from 'react-icons/fa6' /* Security Awareness Training */
import { MdOutlineMailLock } from "react-icons/md"; /* Encrypted Email */
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined' /* MDM */
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined' /* Penetration Testing */
import { MdOutlineMail } from "react-icons/md"
import '../../index.css'

const NetworkCyberSecurity = (props) => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const colors = tokens(mode)
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  const isPhone = useMediaQuery(theme.breakpoints.down('tablet'))
  const isTablet = !isPhone && !isDesktop

  return (
    <div className='services' style={{
      display:"flex",
      flexDirection:"column",
      overflow:"hidden",
      width:"100%",
      margin:0
    }}>
      {isDesktop ? <>
        <div style={{display:"flex", flexDirection:"column", textAlign:"center"}}>
          <div>
            <h1 style={{fontSize:"2rem"}}>
              Network & Cybersecurity
            </h1>
            <div style={{margin:"2rem 12rem", textAlign:"center"}}>
              <Typography variant="p" fontSize={22} fontWeight="normal">
                Our multi-layered, best-of-breed approach to network security ensures all aspects of the network are covered.
              </Typography>
            </div>
          </div>
        </div>
        <div style={{width:"90%", margin:"2rem auto", height:"1rem",backgroundColor:colors.green['primary'], opacity:0.7, verticalAlign:"top"}}></div>
        <div style={{margin:"2rem 1rem 0 1rem", fontSize:17, textAlign:"center", display:"inline-flex", gap:"2rem", justifyContent:"center"}}>
          <Link to='#endpoint-detection-response'>
            <Box sx={{'&:hover': {color:colors.green['primary']},color:colors.primary[500],  borderRadius:"10px"}}>
              <FaCrosshairs fontSize={36} style={{opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Endpoint Detection <br></br>& Response</Typography>
            </Box>
          </Link>

          <Link to='#managed-security-operations-center'>
            <Box sx={{'&:hover': {color:colors.green['primary']},color:colors.primary[500],  borderRadius:"10px"}}>
              <FaNetworkWired fontSize={36} style={{opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Managed Security<br></br>Operations Center</Typography>
            </Box>
          </Link>
        
          <Link to='#vulnerability-management'>
            <Box sx={{'&:hover': {color:colors.green['primary']},color:colors.primary[500],  borderRadius:"10px"}}>
              <SiSecurityscorecard fontSize={36} style={{opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Vulnerability<br></br>Management</Typography>
            </Box>
          </Link>
          <Link to='#virtual-network-penetration-testing'>
            <Box sx={{'&:hover': {color:colors.green['primary']},color:colors.primary[500],  borderRadius:"10px"}}>
              <SecurityOutlinedIcon style={{fontSize:36, opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Virtual Network<br></br>Penetration Testing</Typography>
            </Box>
          </Link>
          <Link to='#security-awareness-training'>
            <Box sx={{'&:hover': {color:colors.green['primary']},color:colors.primary[500],  borderRadius:"10px"}}>
              <FaUserShield fontSize={36} style={{opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Security Awareness<br></br>Training</Typography>
            </Box>
          </Link>
          <Link to='#mobile-device-management'>
            <Box sx={{'&:hover': {color:colors.green['primary']},color:colors.primary[500],  borderRadius:"10px"}}>
              <PhoneIphoneOutlinedIcon style={{fontSize:36, opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Mobile Device<br></br>Management</Typography>
            </Box>
          </Link>
          <Link to='#encrypted-email'>
            <Box sx={{'&:hover': {color:colors.green['primary']},color:colors.primary[500],  borderRadius:"10px"}}>
              <MdOutlineMailLock style={{fontSize:36, opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Encrypted<br></br>Email</Typography>
            </Box>
          </Link>
          <Link to='#advanced-email-threat-detection'>
            <Box sx={{'&:hover': {color:colors.green['primary']}, color:colors.primary[500], borderRadius:"10px"}}>
              <MdOutlineMail fontSize={40} style={{opacity:0.7}}/>
              <Typography variant='h2' fontWeight={400} fontSize={17} fontFamily="Nunito Sans">Advanced Email<br></br>Threat Protection</Typography>
            </Box>
          </Link>
        </div>
        <div style={{
          display:"flex",
          flexDirection:"row",
          padding:"5rem 14rem",
          margin:"4rem 0 2rem 0",
          backgroundColor:mode === 'light' ? colors.grey[800] : colors.grey[700],
          boxShadow: theme.palette.mode == 'light' ? `0 2px 14px `+ colors.primary[700] + ` inset` : ""
        }}>
          <div style={{margin:"auto 2rem auto 0", paddingTop:"0rem"}}><img alt='Network and Cybersecurity Services' src='/services/network-cybersecurity.svg' style={{width:"24rem"}} /></div>
          <div style={{display:"flex", flexDirection:"column", marginTop:"1rem"}}>
            <div style={{margin:"auto 0"}}>
              <Typography variant="p" color={mode === 'light' ? colors.primary[500] : '#fcfcfc'} fontWeight={600} fontSize={16}>
                Whether you call it a Managed Security Services Provider (MSSP), Security as a Service (SECaaS), Cyber Security or just plain old IT Security, we have you covered. Cyber Security standards are generally applicable to all organizations regardless of their size or the industry and sector in which they operate. We setup our standards to protect your network from every threat possible. Our security team knows your data is only as secure as the weakest point on your network. We perform ongoing security audits to ensure your network stays protected from internal and external threats.
                <br></br><br></br>
                Our multi-layered, best-of-breed approach to network security ensures all aspects of the network are covered. Using hardened firewall services, hosted antimalware/antivirus, Windows and 3rd party patching, spam and web filtering, multi-factor authentication, and 24×7 network monitoring and response services, we have you covered. We partner with the industry leader in Security Awareness Training to ensure your employees are educated and trained to spot the major security attacks such as CEO Fraud, Phishing & Spear Phishing, Social Engineering, Ransonware, and more.
                <br></br><br></br>
                No matter what industry your company is in, we’ll ensure compliance with all major security standards such as HIPAA and SOX. Call us for a Security & Technology Assessment and custom action plan to get your network protected at all levels.
                <p className='service' id='endpoint-detection-response' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
        </div>

        <div style={{marginBottom:"1rem", position:"relative"}}>
          <div style={{display:'flex', flexDirection:"row", margin:"5rem 15rem 4rem 15rem"}}>
            <div style={{display:"flex", flexDirection:"column", marginTop:"1rem"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Endpoint Threat Detection & Response (EDR)
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Many of today’s cybercriminals can bypass traditional defenses at will. This leaves businesses exposed to ransomware, credential harvesting, data theft, extortion, and other types of attacks that can cost thousands of dollars per hour from the time of the known attack until remediation. Now more than ever, businesses need advanced Endpoint Detection & Response (EDR) in addition to having an antivirus (AV) installed on each endpoint.
                <br></br><br></br>
                EDR is a behavior-based system that enables the detection of and response to sophisticated attacks in real time with continuous endpoint monitoring, deep-memory analysis to monitor for attackers that attempt to lay in wait, mapping of certain alerts and behaviors that mimic security threats onto the MITRE ATT&CK Framework and integration into a Managed Security Operations Center (SOC) Team for 24/7 real-time analysis and actions. Endpoints can be isolated, processes can be terminated, files can be deleted and that is just the beginning. EDR is quickly becoming a must have for Cyber Insurance qualifications as well.
                <p className='service' id='managed-security-operations-center' style={{lineHeight:1}}></p>
              </Typography>
            </div>
            <div style={{margin:"auto"}}><img alt='Endpoint Threat Detection and Response' src='/services/endpoint-detection-response.svg' style={{width:"18rem"}} /></div>
          </div>
          <div style={{display:'flex', flexDirection:"row", margin:"6rem 15rem 5rem 15rem"}}>
            <div style={{margin:"auto 1rem auto 0"}}><img alt='Security Operations Center' src='/services/security-operations-center.svg' style={{width:"18rem"}} /></div>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Managed Security Operations Center
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                We provide a comprehensive threat detection, prevention and response system which includes 24/7 monitoring and expert analysis to identify and prevent attacks across all attack vectors, proactive detection & prevention, real-time threat investigation, threat hunting, incident response, and log analysis & management. EDR is a great tool by itself but only monitors and protects at the endpoint level.<br></br><br></br>Managed SOC analyzes and responds to threats across all parts of the organization – the endpoints, the company’s network as well as cloud resources. This ensures that we have a comprehensive monitoring tool across the entire client footprint. Managed SOC is quickly becoming a security standard when applying for Cyber Insurance. Additionally, more than ever insurance and audit companies want to see that their customers are able to pull and analyze logs pertaining to possible breaches should they occur. Our security team can upgrade your security posture with our full suite of cyber security solutions. Please contact us for more details!
                <p className='service' id='vulnerability-management' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div style={{display:'flex', flexDirection:"row", margin:"5rem 15rem"}}>
            <div style={{display:"flex", flexDirection:"column", marginTop:"1rem"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Internal & External Vulnerability Management
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Vulnerability Scanning has become an essential tool in evaluating the overall We have partnered with VulScan to provide cost-effective internal and external network scanning to satisfy one of The National Institute of Standards and Technology’s most important Cybersecurity Framework recommendations. Once installed, our network scanners will scan the internal network for issues such as operating system and security misconfigurations, unauthorized access to systems, assess practices and polices related to both private and public networks to detect known anomalies, application configuration settings, application patch history and update recommendations, wireless networks, database configurations & settings, and more. This system will generate action items for our support team to remediate and resolve to ensure the network maintains the highest security standards possible.<br></br><br></br>The external scanning will monitor the network security and health from outside the organization and target areas such as the company’s firewall, port configuration, web applications, networks, etc. External scanning is as important as internal scanning to ensure that attackers have as few options as possible to penetrate the network. With continuous system vulnerability scanning, this ensures that if/when any network breach occurs, it is located and remediated so that an attacker cannot gain a foothold into the network, dwell and gather critical network information in order to initiate a crippling security attack. In addition, VulScan satisfies compliance for a number of security standards such as GDPR, HIPAA and PCI DSS.
                <p className='service' id='virtual-network-penetration-testing' style={{lineHeight:1}}></p>
              </Typography>
            </div>
            <div style={{margin:"auto"}}><img alt='Vulnerability Management' src='/services/vulnerability-management.svg' style={{width:"18rem"}} /></div>
          </div>
          <div style={{display:'flex', flexDirection:"row", margin:"6rem 15rem 5rem 15rem"}}>
            <div style={{margin:"auto 1rem auto 0"}}><img alt='Virtual Network Penetration Testing' src='/services/penetration-testing.svg' style={{width:"18rem"}} /></div>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Virtual Network Penetration Testing
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                vPenTesting is an automated network penetration testing platform that combines the knowledge, methodology, processes, and toolsets of a hacker into a single, deployable SaaS platform for organizations of all sizes. vPenTest allows organizations to perform a penetration test within their environment at any given time, satisfying both compliance requirements as well as meeting security best practices. This platform is developed and maintained solely by Vonahi Security and is based on a framework that continuously improves over time.
                <p className='service' id='security-awareness-training' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div style={{display:'flex', flexDirection:"row", margin:"6rem 15rem 5rem 15rem"}}>
            <div style={{display:"flex", flexDirection:"column", marginTop:"1rem"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Security Awareness Training
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                60% of Small Businesses fold within six months of a cyber attack. The weakest link in any network environment is and will always be the the end-user. With sophisticated attacks like Ransomware, Phishing & Spearphishing, CEO Fraud, Email Spoofing and Keyloggers, it is imperative that employees are knowledgeable and aware of these attacks.
                <br></br><br></br>
                We have partnered with the industry leader in Security Awareness Training to ensure that all employees become experts in spotting and avoiding these types of cyber attacks. Our security team sends out simulated email phishing campaigns to all employees and then reports on the findings. We then continue to test offenders and assign training to ensure they become more diligent and knowledgeable. We assist our clients with their employee onboarding by assigning training modules as soon as they are hired. Ask us how we can supplement your security profile with Security Awareness Training.
                <p className='service' id='mobile-device-management' style={{lineHeight:1}}></p>
              </Typography>
            </div>
            <div style={{margin:"auto"}}><img alt='Security Awareness Training' src='/services/security-awareness-training.svg' style={{width:"18rem"}} /></div>
          </div>
          <div style={{display:'flex', flexDirection:"row", margin:"6rem 15rem 5rem 15rem"}}>
            <div style={{margin:"auto 1rem auto 0"}}><img alt='Mobile Device Management' src='/services/mobile-device-management.svg' style={{width:"18rem"}} /></div>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Mobile Device Management (MDM)
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                With businesses becoming more mobile every day, the importance of having business application access on mobile devices is more important than ever. However, with that increased access comes the responsibility to ensure that the mobile devices are secure and your IT Department has a pulse on them. We have non-invasive management tools that allow us to remotely setup, secure and manage any type of mobile device or tablet. We have the ability to remotely deploy wifi networks, security policies, email accounts, business applications, etc. and we can do it all from our management console, without ever physically touching the devices. Ask us how we can secure your mobile devices so that you can sleep better at night!
                <p className='service' id='encrypted-email' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div style={{display:'flex', flexDirection:"row", margin:"6rem 15rem 5rem 15rem"}}>
            <div style={{display:"flex", flexDirection:"column", marginTop:"1rem"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Encrypted Email
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Important personal and corporate data is sent via email all the time. The problem is that attackers can easily capture email data sent over clear text. Now more than ever, it is important to ensure that any important data such as account numbers, social security numbers, passwords, etc. are sent over a secure channel making it much more difficult for this information to be intercepted. If you are looking for a solution that seamlessy integrates into your business email and is super easy to use, give us a call!
                <p className='service' id='advanced-email-threat-detection' style={{lineHeight:1}}></p>
              </Typography>
            </div>
            <div style={{margin:"auto"}}><img alt='Encrypted Email' src='/services/encrypted-email.svg' style={{width:"18rem"}} /></div>
          </div>
          <div style={{display:'flex', flexDirection:"row", margin:"6rem 15rem 5rem 15rem"}}>
            <div style={{margin:"auto 1rem auto 0"}}><img alt='Advanced Email Threat Protection' src='/services/advanced-spam-filtering.webp' style={{width:"18rem"}} /></div>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
              <Typography variant='h2' fontSize={32} style={{textAlign:"left", padding:"0 0 0 2rem", textWrap:"nowrap"}}>
                Advanced Email Threat Protection
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Over 90% of all cyberattacks originate with email.  With over 99.9% catch-rate and extremely low false-positives, our Advanced Email Threat Protection will ensure that the bad emails stay out, while the valid emails make it to your inbox.  We have a 24/7/365 Live Threat Analysis Team constantly identifying, updating and blocking new threats.  Any link within an email is analyzed before opening to ensure links are valid and known to be good.  All emails are scanned for illegitimate attachments which are automatically removed and quarantined into a cloud-based portal for review.  We can retract an email from an entire Microsoft Office 365 organization with one click.  Our team of experts can implement this service with no downtime so that you never miss an email!
              </Typography>
            </div>
          </div>
      </div>
      <ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </>
      :
      <>
        <div style={{display:"flex", flexDirection:"column", textAlign:isPhone ? "left" : "center"}}>
          <div style={{margin:"1rem 2rem 3rem 2rem"}}>
            <h1 style={{fontSize:"1.5rem", lineHeight:"1.8rem", margin:"0 2rem"}}>
              Network & Cybersecurity
            </h1>
            <div style={{margin:"2rem", textAlign:isPhone ? "left" : "center"}}>
              <Typography variant="p" fontSize={22} fontWeight="normal">
                Our multi-layered, best-of-breed approach to network security ensures all aspects of the network are covered.
              </Typography>
            </div>
          </div>
        </div>
        <div style={{
          display:"flex",
          flexDirection:"column",
          padding:isPhone ? "2rem 4rem 4rem 4rem" : "3rem 8rem",
          margin:0,
          backgroundColor:colors.grey[800],
          boxShadow:`0 2px 14px `+ colors.primary[700] + ` inset`
        }}>
          <div style={{textAlign:"center", margin:"2rem 0"}}><img alt='Network and Cybersecurity Services' src='/services/network-cybersecurity.svg' style={{width:isTablet ? "20rem" : "14rem"}} /></div>
          <div style={{display:"flex", flexDirection:"column"}}>
            <div style={{marginTop:"0.5rem"}}>
              <Typography variant="p" color={mode === 'light' ? colors.primary[500] : '#fcfcfc'} fontWeight={600} fontSize={16}>
                Whether you call it a Managed Security Services Provider (MSSP), Security as a Service (SECaaS), Cyber Security or just plain old IT Security, we have you covered. Cyber Security standards are generally applicable to all organizations regardless of their size or the industry and sector in which they operate. We setup our standards to protect your network from every threat possible. Our security team knows your data is only as secure as the weakest point on your network. We perform ongoing security audits to ensure your network stays protected from internal and external threats.
                <br></br><br></br>
                Our multi-layered, best-of-breed approach to network security ensures all aspects of the network are covered. Using hardened firewall services, hosted antimalware/antivirus, Windows and 3rd party patching, spam and web filtering, multi-factor authentication, and 24×7 network monitoring and response services, we have you covered. We partner with the industry leader in Security Awareness Training to ensure your employees are educated and trained to spot the major security attacks such as CEO Fraud, Phishing & Spear Phishing, Social Engineering, Ransonware, and more.
                <br></br><br></br>
                No matter what industry your company is in, we’ll ensure compliance with all major security standards such as HIPAA and SOX. Call us for a Security & Technology Assessment and custom action plan to get your network protected at all levels.
                <p className='service' id='endpoint-detection-response' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
        </div>
        <div className='service' style={{margin:"2rem 0", position:"relative"}}>
          <div style={{display:'flex', margin:isTablet ? "4rem" : "2rem"}}>
            <div style={{display:"flex", flexDirection:"column", marginTop:"1rem"}}>
              <div style={{margin:"1rem auto"}}><img alt='Endpoint Threat Detection and Response' src='/services/endpoint-detection-response.svg' style={{width:"10rem"}} /></div>
              <Typography variant='h2' fontSize={22} style={{textAlign:"center", padding:"", textWrap:isPhone ? "wrap" : "nowrap"}}>
                Endpoint Threat Detection & Response (EDR)
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Many of today’s cybercriminals can bypass traditional defenses at will. This leaves businesses exposed to ransomware, credential harvesting, data theft, extortion, and other types of attacks that can cost thousands of dollars per hour from the time of the known attack until remediation. Now more than ever, businesses need advanced Endpoint Detection & Response (EDR) in addition to having an antivirus (AV) installed on each endpoint.
                <br></br><br></br>
                EDR is a behavior-based system that enables the detection of and response to sophisticated attacks in real time with continuous endpoint monitoring, deep-memory analysis to monitor for attackers that attempt to lay in wait, mapping of certain alerts and behaviors that mimic security threats onto the MITRE ATT&CK Framework and integration into a Managed Security Operations Center (SOC) Team for 24/7 real-time analysis and actions. Endpoints can be isolated, processes can be terminated, files can be deleted and that is just the beginning. EDR is quickly becoming a must have for Cyber Insurance qualifications as well.
                <p className='service' id='managed-security-operations-center' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? " 2rem 4rem 4rem 4rem" : "2rem"}}>
            <div style={{margin:"0 auto 1rem auto"}}><img alt='Security Operations Center' src='/services/security-operations-center.svg' style={{width:"10rem"}} /></div>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
              <Typography variant='h2' fontSize={22} style={{textAlign:"center", padding:"", /* textWrap:isPhone ? "wrap" : "nowrap" */}}>
                Managed Security Operations Center
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                We provide a comprehensive threat detection, prevention and response system which includes 24/7 monitoring and expert analysis to identify and prevent attacks across all attack vectors, proactive detection & prevention, real-time threat investigation, threat hunting, incident response, and log analysis & management. EDR is a great tool by itself but only monitors and protects at the endpoint level.<br></br><br></br>Managed SOC analyzes and responds to threats across all parts of the organization – the endpoints, the company’s network as well as cloud resources. This ensures that we have a comprehensive monitoring tool across the entire client footprint. Managed SOC is quickly becoming a security standard when applying for Cyber Insurance. Additionally, more than ever insurance and audit companies want to see that their customers are able to pull and analyze logs pertaining to possible breaches should they occur. Our security team can upgrade your security posture with our full suite of cyber security solutions. Please contact us for more details!
                <p className='service' id='vulnerability-management' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
              <div style={{margin:"1rem auto"}}><img alt='Vulnerability Management' src='/services/vulnerability-management.svg' style={{width:"10rem"}} /></div>
              <Typography variant='h2' fontSize={22} style={{textAlign:"center"}}>
                Internal & External Vulnerability Management
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Vulnerability Scanning has become an essential tool in evaluating the overall We have partnered with VulScan to provide cost-effective internal and external network scanning to satisfy one of The National Institute of Standards and Technology’s most important Cybersecurity Framework recommendations. Once installed, our network scanners will scan the internal network for issues such as operating system and security misconfigurations, unauthorized access to systems, assess practices and polices related to both private and public networks to detect known anomalies, application configuration settings, application patch history and update recommendations, wireless networks, database configurations & settings, and more. This system will generate action items for our support team to remediate and resolve to ensure the network maintains the highest security standards possible.<br></br><br></br>The external scanning will monitor the network security and health from outside the organization and target areas such as the company’s firewall, port configuration, web applications, networks, etc. External scanning is as important as internal scanning to ensure that attackers have as few options as possible to penetrate the network. With continuous system vulnerability scanning, this ensures that if/when any network breach occurs, it is located and remediated so that an attacker cannot gain a foothold into the network, dwell and gather critical network information in order to initiate a crippling security attack. In addition, VulScan satisfies compliance for a number of security standards such as GDPR, HIPAA and PCI DSS.
                <p className='service' id='virtual-network-penetration-testing' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
              <div style={{margin:"1rem auto"}}><img alt='Virtual Network Penetration Testing' src='/services/penetration-testing.svg' style={{width:"10rem"}} /></div>
              <Typography variant='h2' fontSize={22} style={{textAlign:"center"}}>
                Virtual Network Penetration Testing
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
              vPenTesting is an automated network penetration testing platform that combines the knowledge, methodology, processes, and toolsets of a hacker into a single, deployable SaaS platform for organizations of all sizes. vPenTest allows organizations to perform a penetration test within their environment at any given time, satisfying both compliance requirements as well as meeting security best practices. This platform is developed and maintained solely by Vonahi Security and is based on a framework that continuously improves over time. 
                <p className='service' id='security-awareness-training' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
              <div style={{margin:"1rem auto"}}><img alt='Security Awareness Training' src='/services/security-awareness-training.svg' style={{width:"10rem"}} /></div>
              <Typography variant='h2' fontSize={22} style={{textAlign:"center"}}>
                Security Awareness Training
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                60% of Small Businesses fold within six months of a cyber attack. The weakest link in any network environment is and will always be the the end-user. With sophisticated attacks like Ransomware, Phishing & Spearphishing, CEO Fraud, Email Spoofing and Keyloggers, it is imperative that employees are knowledgeable and aware of these attacks.
                <br></br><br></br>
                We have partnered with the industry leader in Security Awareness Training to ensure that all employees become experts in spotting and avoiding these types of cyber attacks. Our security team sends out simulated email phishing campaigns to all employees and then reports on the findings. We then continue to test offenders and assign training to ensure they become more diligent and knowledgeable. We assist our clients with their employee onboarding by assigning training modules as soon as they are hired. Ask us how we can supplement your security profile with Security Awareness Training.
                <p className='service' id='mobile-device-management' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
              <div style={{margin:"1rem auto"}}><img alt='Mobile Device Management' src='/services/mobile-device-management.svg' style={{width:"10rem"}} /></div>
              <Typography variant='h2' fontSize={22} style={{textAlign:"center"}}>
                Mobile Device Management (MDM)
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                With businesses becoming more mobile every day, the importance of having business application access on mobile devices is more important than ever. However, with that increased access comes the responsibility to ensure that the mobile devices are secure and your IT Department has a pulse on them. We have non-invasive management tools that allow us to remotely setup, secure and manage any type of mobile device or tablet. We have the ability to remotely deploy wifi networks, security policies, email accounts, business applications, etc. and we can do it all from our management console, without ever physically touching the devices. Ask us how we can secure your mobile devices so that you can sleep better at night!
                <p className='service' id='encrypted-email' style={{lineHeight:1}}></p>
              </Typography>
            </div>
          </div>
          <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
              <div style={{margin:"1rem auto"}}><img alt='Encrypted Email' src='/services/encrypted-email.svg' style={{width:"10rem"}} /></div>
              <Typography variant='h2' fontSize={22} style={{textAlign:"center"}}>
                Encrypted Email
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Important personal and corporate data is sent via email all the time. The problem is that attackers can easily capture email data sent over clear text. Now more than ever, it is important to ensure that any important data such as account numbers, social security numbers, passwords, etc. are sent over a secure channel making it much more difficult for this information to be intercepted. If you are looking for a solution that seamlessy integrates into your business email and is super easy to use, give us a call!
              </Typography>
            </div>
            <p className='service' id='advanced-email-threat-detection' style={{lineHeight:1}}></p>
          </div>
          <div className='service' style={{display:'flex', flexDirection:"column", margin:isTablet ? "4rem" : "2rem"}}>
            <div style={{display:"flex", flexDirection:"column", margin:"auto 1rem"}}>
              <div style={{margin:"1rem auto"}}><img alt='Advanced Email Threat Protection' src='/services/advanced-spam-filtering.webp' style={{width:"10rem"}} /></div>
              <Typography variant='h2' fontSize={22} style={{textAlign:"center"}}>
                Advanced Email Threat Protection
              </Typography>
              <Typography variant='p' style={{padding:"2rem", fontSize:17}}>
                Over 90% of all cyberattacks originate with email.  With over 99.9% catch-rate and extremely low false-positives, our Advanced Email Threat Protection will ensure that the bad emails stay out, while the valid emails make it to your inbox.  We have a 24/7/365 Live Threat Analysis Team constantly identifying, updating and blocking new threats.  Any link within an email is analyzed before opening to ensure links are valid and known to be good.  All emails are scanned for illegitimate attachments which are automatically removed and quarantined into a cloud-based portal for review.  We can retract an email from an entire Microsoft Office 365 organization with one click.  Our team of experts can implement this service with no downtime so that you never miss an email!
              </Typography>
            </div>
          </div>
        </div>
      </>      
      }
    </div>
    )
  }


export default NetworkCyberSecurity