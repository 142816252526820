import { Box, Checkbox, useMediaQuery, useTheme } from "@mui/material"
import { tokens } from "../theme"
import PropTypes from 'prop-types'
import '../index.css'

const InterestCheckbox = ({ name, interests, onChange }) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  return (
    <label style={{fontSize:16}}>
    <Checkbox
      disableRipple
      sx={{
        m:"-0.2rem 0 0 -0.3rem",
        '&.Mui-checked':{color:colors.green['primary']},
        transform:"scale(0.9)"
      }}
      name={name}
      value={!interests[{name}]}
      onChange={onChange}
    />
    {name}
  </label>
  )
}

InterestCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  interests: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

const Interests = ({ interests, onChangeInterest }) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))

  return (
    <Box sx={{
      '&.Mui-checked':{color:colors.green['primary']},
      '& .css-uxaagx-MuiButtonBase-root-MuiCheckbox-root': {p:"4px"},
      m:isDesktop ? "2rem 0" : "2rem 0.5rem",
      width:'100%',
      display:"flex",
      flexDirection:isDesktop ? "row" : "column",
      textAlign:"left",
      justifyContent:"space-between"
    }}>
      <div style={{marginLeft:"0.7rem"}}>
        <p style={{color:"#a3a3a3", fontWeight:400, fontSize:18, textWrap:"wrap"}}>Services:</p>
        <p style={{color:"#a3a3a3", fontWeight:400, fontSize:14, marginBottom:"0.5rem"}}>(check all that apply)</p>
      </div>
      <ul style={{ display:"flex", flexDirection:"column"}} role="group" aria-labelledby="checkbox-group">
        <InterestCheckbox name='Managed IT Services' interests={interests} onChange={onChangeInterest} />
        <InterestCheckbox name='Cybersecurity' interests={interests} onChange={onChangeInterest} />
        <InterestCheckbox name='Cloud Solutions' interests={interests} onChange={onChangeInterest} />
        <InterestCheckbox name='Network Services' interests={interests} onChange={onChangeInterest} />
      </ul>
      <ul style={{ display:"flex", flexDirection:"column"}} role="group" aria-labelledby="checkbox-group">
        <InterestCheckbox name='Business Continuity / Disaster Recovery' interests={interests} onChange={onChangeInterest} />
        <InterestCheckbox name='Voice over IP (VoIP)' interests={interests} onChange={onChangeInterest} />
        <InterestCheckbox name='Managed Print Services' interests={interests} onChange={onChangeInterest} />
        <InterestCheckbox name='Mobile Device Management' interests={interests} onChange={onChangeInterest} />
      </ul>
      <ul style={{ display:"flex", flexDirection:"column"}} role="group" aria-labelledby="checkbox-group">
        <InterestCheckbox name='Technology Procurement' interests={interests} onChange={onChangeInterest} />
        <InterestCheckbox name='Secure Business Internet' interests={interests} onChange={onChangeInterest} />
        <InterestCheckbox name='Spam Filtering / Encrypted Email' interests={interests} onChange={onChangeInterest} />
        <InterestCheckbox name='Security Awareness Training' interests={interests} onChange={onChangeInterest} />
      </ul>
    </Box>
  )
}

Interests.propTypes = {
  interests: PropTypes.array.isRequired,
  onChangeInterest: PropTypes.func.isRequired
}

export default Interests