import { Fab, Typography, useMediaQuery, useTheme } from '@mui/material'
import ScrollTop from '../../components/buttons/ScrollToTop'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const OurStory = (props) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  const isPhone = useMediaQuery(theme.breakpoints.down('tablet'))

  return (
    <div className='page' style={{
      display:"flex",
      flexDirection:"column",
      overflow:"hidden"
    }}>
      <div style={{display:"flex", flexDirection:"column", textAlign:isPhone ? "left" : "center"}}>
        <div style={{margin:isDesktop ? "" : "1rem 2rem"}}>
          <h1 style={{fontSize:isDesktop ? "2rem" : "1.5rem"}}>
            Our Story
          </h1>
        </div>
        <div style={{margin:isDesktop ? "2rem 3rem" : "2rem", textAlign:"left"}}>
          <Typography variant="p" fontSize={22} fontWeight="normal">
            Founders Angel Cifuentes and Michael Sagoian have over 50 years of combined experience in the technology industry. Their vision for 7 Layer IT Solutions started in 2007 in a small break/fix shop in Encino, CA. Together Angel and Michael grew their connections in their community through their trusted expertise. After many long hours of dedication and sacrifice, they launched a business of their own, 7 Layer IT Solutions.
            <br></br><br></br>
            Through the years, the business has grown to support various industries and thousands of professionals in legal firms, healthcare clinics, non-profits, small businesses and much more.
          </Typography>
        </div>
      </div>
        {/* <div style={{display:"flex", flexDirection:"column", padding:isDesktop ? "2.5rem 8rem" : "2.5rem"}}>
          <Typography variant="h1" color={colors.grey[300]} fontSize={isDesktop ? 24 : 20}>7 LAYERS OF SECURITY</Typography>
          <div style={{marginTop:"1.5rem"}}>
            <ol style={{margin:0, padding:"0.7rem 0 3rem 0"}}>
              <li><Typography variant='h2' fontSize={20}>Phyical Security</Typography></li>
              <li><Typography variant='h2' fontSize={20}>Network Security</Typography></li>
              <li><Typography variant='h2' fontSize={20}>Perimeter Security</Typography></li>
              <li><Typography variant='h2' fontSize={20}>Endpoint Security</Typography></li>
              <li><Typography variant='h2' fontSize={20}>Application Security</Typography></li>
              <li><Typography variant='h2' fontSize={20}>Data Security</Typography></li>
              <li><Typography variant='h2' fontSize={20}>User Education</Typography></li>
            </ol>
          </div>
        </div> */}
      {isDesktop && <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>}
    </div>
  )
}

export default OurStory