import { Fab, Typography, useMediaQuery, useTheme } from '@mui/material'
import ScrollTop from '../../components/buttons/ScrollToTop'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const OurMission = (props) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  const isPhone = useMediaQuery(theme.breakpoints.down('tablet'))

  return (
    <div className='page' style={{
      display:"flex",
      flexDirection:"column",
      overflow:"hidden"
    }}>
      <div style={{display:"flex", flexDirection:"column", textAlign:isPhone ? "left" : "center"}}>
        <div style={{margin:isDesktop ? "" : "1rem 2rem"}}>
          <h1 style={{fontSize:isDesktop ? "2rem" : "1.5rem"}}>Our Mission</h1>
        </div>
        <div style={{margin:isDesktop ? "2rem 3rem" : "2rem", textAlign:"left"}}>
          <Typography variant="p" fontSize={22} fontWeight="normal">
            We will strive to provide our clients with exceptional and cutting-edge IT solutions and support at affordable prices, no matter what. We will design, implement and manage secure, reliable and cost-effective networks whether the client has two or 2,000 users. We will always maintain that personal connection with our clients and our dependability will be second to none.
          </Typography>
        </div>
      </div>
      {isDesktop && <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>}
    </div>
  )
}

export default OurMission